import React,{useState,useEffect} from "react";
import {useForm} from 'react-hook-form';
import Axios from "axios";
import Navigation from "../components/Navigation";
import ReadCatalogue from "../components/ReadCatalogue";
import "../css/HomePage.css";
import Constant from "../config/Constant";

const HOST = Constant.HOST;

function Catalogues() {
    const {register} = useForm();
    const [search,setSearch] = useState(null);
    const [postList,setPostList] = useState([]);


    useEffect(() => {
        Axios.get(`${HOST}/api/catalogue/get`)
            .then((result) => {
                setPostList(result.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },[]);

    function searchPostList(search) {
        Axios.get(`${HOST}/api/catalogue/get/${search}`).then((result) => {
            setPostList(result.data);
        });
    }

    return (
        <div className='container mt-4'>
            <Navigation page="Catalogues" />
            <hr className="featurette-divider" />
            <div className="row mt-5">
                <div className="col-md-6 offset-md-3">
                    <div className="input-group">
                        <input className="form-control" type="text" placeholder="Search context..." {...register("Search",{required: false,maxLength: 80})}
                            onChange={(e) => {setSearch(e.target.value)}}
                        />
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={(() => searchPostList(search))}>Search</button>
                    </div>
                </div>
            </div>
            <div id="columns">
                {postList.map((val,key) => {
                    const url = (`${HOST}/catalogue/` + (val.cover));
                    return (
                        <figure key={val.id}>
                            <img src={url} alt="" />
                            <figcaption>{val.title}</figcaption>
                            <ReadCatalogue id={val.id} likes={val.likes} pages={val.pages} />
                        </figure>
                    );
                })}
            </div>
        </div>
    );
}

export default Catalogues;
