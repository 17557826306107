import React,{useState,useEffect,useRef} from 'react';
import {useLocation} from 'react-router-dom';
import Axios from 'axios';
import {MathJax,MathJaxContext} from "better-react-mathjax";
import {Viewer} from '@react-pdf-viewer/core'; // install this library
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {Worker} from '@react-pdf-viewer/core'; // install this library

import Constant from '../config/Constant';
import TagList from '../config/TagList';
import Navigation from "../components/Navigation";

const HOST = Constant.HOST;




export default function PostManager() {

    const {state} = useLocation();
    const {id_nav} = state;

    const [id,setId] = useState(id_nav);

    const [key,setKey] = useState(0);


    const [ids,setIds] = useState(0);
    const [code,setCode] = useState(0);

    const [error,setError] = useState(null);
    const [success,setSuccess] = useState(null);



    function PostPreview(props) {
        const defaultLayoutPluginInstance = defaultLayoutPlugin();
        const config = {
            loader: {load: ["input/asciimath"]}
        };
        const id = props.id;
        const [title,setTitle] = useState();
        const [postContent,setPostContent] = useState([]);
        const [refs,setRefs] = useState([]);


        useEffect(() => {

            Axios.get(`${HOST}/api/post/getlist/id/${id}`)
                .then((result) => {
                    if(result.data.length > 0) {
                        setTitle(result.data[0].title);
                    }
                });

            Axios.get(`${HOST}/api/post/getcontent/id/${id}`)
                .then((result) => {
                    setPostContent(result.data);
                    const refs = (result.data).reduce((acc,value,index) => {
                        acc[index] = React.createRef();
                        return acc;
                    },{});
                    setRefs(refs);
                })
                .catch((error) => {
                    console.log(error);
                });
        },[id]);

        // const gotoEndPage = () => {
        //     var objDiv = document.getElementById('page');
        //     document.documentElement.scrollTop = objDiv.scrollHeight;
        // }


        const handleScroll = index =>
            refs[index].current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });



        const handleDeleteContent = (code) => {
            Axios.delete(`${HOST}/api/post/deletecontent/${code}`).then((response) => {
                alert("Content deleted!");
            });
            setKey((k) => k + 1);
        }

        const handleDeleteImage = (code,image) => {
            Axios.delete(`${HOST}/api/post/deleteimage/${code}/${image}`).then((response) => {
                alert("Content deleted!");
            });
            setKey((k) => k + 1);
        }

        const handleEditTitle = (ids) => {
            setIds(ids);
            setCode(0);
        }

        const handleEditContent = (ids,code) => {
            //setShowEdit(true);
            setIds(ids);
            setCode(code);
            //setCurrentIndex(index);
            // document.documentElement.scrollTop = 0
            // var objDiv = document.getElementById('page');
            // document.documentElement.scrollTop = objDiv.scrollHeight;
        }



        const AElement = (props) => {
            var text = props.text;
            const checkA = text.indexOf(`[a]`);
            if(checkA > -1) {
                const aaa = text.split(`[a]`);
                return (
                    <>
                        {aaa[0]}
                        <span
                            style={{
                                cursor: 'pointer',
                                color: 'blue',
                                textDecoration: 'underline'
                            }}
                            onClick={() => handleScroll(props.link)}
                        >{aaa[1]}</span>
                        {aaa[2]}
                    </>
                )
            } else {
                return (
                    <>{text}</>
                )
            }
        }

        const ContentElement = (props) => {
            var text = props.text;
            const checkS = text.indexOf(`[s]`);
            const checkA = text.indexOf(`[a]`);
            if(checkS > -1) {
                const sss = text.split(`[s]`);
                return (
                    <>
                        {sss.map((val,key) => {
                            return (
                                ((key % 2 === 0) ?
                                    (<AElement key={key} link={props.link} text={sss[key]} />) :
                                    (<strong key={key}><AElement link={props.link} text={sss[key]} /></strong>)
                                )
                            )
                        })}
                    </>
                )

            } else if(checkA > -1) {
                const aaa = text.split(`[a]`);
                return (
                    <>
                        {aaa.map((val,key) => {
                            return (
                                ((key % 2 === 0) ?
                                    (<AElement key={key} link={props.link} text={aaa[key]} />) :
                                    <span
                                        key={key}
                                        style={{
                                            cursor: 'pointer',
                                            color: 'blue',
                                            textDecoration: 'underline'
                                        }}
                                        onClick={() => handleScroll(props.link)}
                                    >{aaa[key]}</span>
                                )
                            )
                        })}
                    </>
                )
            } else {
                return (
                    <>{text}</>
                )
            }

        }


        return (
            <div id='page' className='container-lg mt-5'>
                <hr className="featurette-divider" />
                <div style={{'color': 'rgb(80,80,80)','backgroundColor': 'rgb(200,200,200)','lineHeight': 1.3}}>
                    <div id="wrapper">
                        <nav>
                            <div>
                                <h1>{title}</h1>
                                <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditTitle(id))}>Edit</button>
                                {/* <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => gotoEndPage())}>Goto</button> */}
                            </div>
                            <div id="links">
                                <a href="/">Home</a>
                                <a href="/admin">Admin</a>
                                <a href="/postsedit">PostsEdit</a>
                            </div>
                        </nav>
                        <article>
                            <section>
                                <MathJaxContext config={config}>
                                    {postContent.map((val,key) => {
                                        let filePath = `${HOST}/post/` + val.content;
                                        switch(val.tag) {
                                            case "image":
                                                return (
                                                    <div ref={refs[key]} id={val.code} key={key}>
                                                        <img src={`${HOST}/post/` + val.content} alt="" style={{
                                                            "border": "1px solid black",
                                                            "marginLeft": "1em",
                                                            'marginBottom': '1em',
                                                            "height": "auto",
                                                            "width": "98%"
                                                        }}>
                                                        </img>
                                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteImage(val.code,val.content))}>Delete</button>
                                                    </div>
                                                )
                                            case "pdf":
                                                return (
                                                    <div ref={refs[key]} id={val.code} key={key}>
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                                                            <div style={{height: "90vh"}}>
                                                                <Viewer
                                                                    fileUrl={filePath}
                                                                    plugins={[defaultLayoutPluginInstance]}
                                                                />
                                                            </div>
                                                        </Worker>

                                                        {/* <iframe title="PDF"
                                                            src={`${HOST}/post/` + val.content + "#toolbar=0"}
                                                            style={{
                                                                "border": "2px solid black",
                                                                "scrolling": "auto",
                                                                "toolbar": "1",
                                                                "marginLeft": "1em",
                                                                "height": "90vh",
                                                                "width": "100%"
                                                            }}
                                                        ></iframe> */}
                                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteImage(val.code,val.content))}>Delete</button>
                                                    </div>
                                                )
                                            case "math":
                                                return (
                                                    <div key={key}>
                                                        <p ref={refs[key]} id={val.code} ><MathJax>{val.content}</MathJax></p>
                                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                        <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                    </div>
                                                )
                                            default:
                                                return (
                                                    React.createElement(
                                                        'div',
                                                        {key: key,},
                                                        React.createElement(
                                                            `${val.tag}`,
                                                            {
                                                                ref: refs[key]
                                                            },
                                                            <ContentElement text={val.content} link={val.link} />,
                                                        ),
                                                        React.createElement(
                                                            'button',
                                                            {
                                                                type: "button",
                                                                className: "btn btn-outline-secondary btn-sm",
                                                                onClick: () => handleEditContent(val.id,val.code)
                                                            },
                                                            'Edit',
                                                            React.createElement('span',{className: "badge text-bg-secondary"},`${key} link ${val.link}`)
                                                        ),
                                                        React.createElement(
                                                            'button',
                                                            {
                                                                type: "button",
                                                                className: "btn btn-outline-secondary btn-sm",
                                                                onClick: () => handleDeleteContent(val.code)
                                                            },
                                                            'Delete'
                                                        )
                                                    )
                                                )

                                        }

                                    })}
                                </MathJaxContext>
                            </section>
                        </article>
                    </div>
                </div>
            </div>
        )
    }

    function PostUpload(props) {


        const fileType = ['application/pdf'];

        const [filePdf,setFilepdf] = useState(null);
        const [viewPdf,setViewPdf] = useState(null);

        const [viewImage,setViewImage] = useState(null);
        const [fileImg,setFileImg] = useState(null);


        const [listContent,setListContent] = useState([]);

        const [tagPdf,setTagPdf] = useState(false);
        const [tagImage,setTagImage] = useState(false);


        const [title,setTitle] = useState('');
        const [cover,setCover] = useState('');


        const [tag,setTag] = useState('p');
        const [content,setContent] = useState('');
        const [link,setLink] = useState(0);


        const textareaRef = useRef(null);


        useEffect(() => {

            Axios.get(`${HOST}/api/post/getcontent/id/${id}`)
                .then((result) => {
                    setListContent(result.data);
                })
                .catch((error) => {
                    console.log(error);
                });

        },[]);



        const uploadPdf = async () => {
            try {
                const formData = new FormData();
                formData.append("pdffile",filePdf);
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    }
                };
                const result = await Axios.post(`${HOST}/api/post/uppdf`,formData,config);
                console.log("Result of filePdf upload: ",result);
            } catch(error) {
                console.error(error);
            }
        };


        const getPdf = (e) => {
            return new Promise((resolve,reject) => {
                let selectedFile = e.target.files[0];
                if(selectedFile) {
                    if(selectedFile && fileType.includes(selectedFile.type)) {
                        let reader = new FileReader();
                        reader.readAsDataURL(selectedFile);
                        reader.onloadend = (e) => {
                            resolve(e.target.result);
                        };
                    }
                    else {
                        reject();
                    }
                }
                else {
                    console.log('select your file');
                }
            });
        };



        //===============Up load Image=================


        const uploadCover = async () => {
            try {
                const formData = new FormData();
                formData.append("image",fileImg);
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    }
                };
                const result = await Axios.post(`${HOST}/api/post/up_cover`,formData,config);
                console.log("Result of fileImg upload: ",result);
            } catch(error) {
                console.error(error);
            }
        };

        const uploadImage = async () => {
            try {
                const formData = new FormData();
                formData.append("image",fileImg);
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    }
                };
                const result = await Axios.post(`${HOST}/api/post/upimg`,formData,config);
                console.log("Result of fileImg upload: ",result);
            } catch(error) {
                console.error(error);
            }
        };


        const getImage = (event) => {
            return new Promise((resolve,reject) => {
                if(event.target.files && event.target.files[0]) {
                    let img = window.URL.createObjectURL(event.target.files[0]);
                    resolve(img);
                } else {
                    console.log(console.error());
                    reject();
                }
            });
        };

        const onCoverChange = (event) => {
            setFileImg(event.target.files[0]);
            setCover(event.target.files[0].name);
            setTagImage(true);
            getImage(event)
                .then((result) => {
                    setViewImage(result);
                })
                .catch(error => console.log(error));
        }


        //===============Up load post_list=================

        const handleTitleChange = (e) => {
            setTitle(e.target.value);
        }

        const handlePostTitle = () => {
            if((title !== 0) && (cover !== '')) {
                Axios.post(`${HOST}/api/post/postlist`,{id: id,title: title,cover: cover}).then(response => {
                }).catch(error => {
                    if(error.response.status === 406 || error.response.status === 409) {setError(error.response.data.message)}
                    else {setError("Something went wrong. Please try again later.")}
                });
                if(fileImg) {
                    uploadCover();
                }
                setSuccess('The Post has been created!');
                setContent('');
                setKey((k) => k + 1);
            } else {
                console.log('Please enter a Title & Cover!');
            }
        };

        //===============Up load post_content=================




        const handlePostContent = () => {
            //console.log(content);
            if(content !== '') {
                Axios.post(`${HOST}/api/post/postcontent`,{id: id,tag: tag,content: content,link: link}).then(response => {
                    //console.log(response.data.serverStatus);
                }).catch(error => {
                    if(error.response.status === 406 || error.response.status === 409) {setError(error.response.data.message)}
                    else {setError("Something went wrong. Please try again later.")}
                });
                if(fileImg) {
                    uploadImage();
                }
                if(filePdf) {
                    uploadPdf();
                }
                setSuccess('This Content has been upload!');
                setContent('');
                setKey((k) => k + 1);
            } else {
                console.log('Please enter a content!');
            }
        };



        const defaultStyle = {
            resize: "none",
            width: "100%",
            height: "0px"
        };

        const handleContentChange = (e) => {
            //e.preventDefault();
            setFileImg(null);
            setFilepdf(null);
            switch(tag) {
                case "image":
                    setContent(e.target.files[0].name);
                    setFileImg(e.target.files[0]);
                    setTagImage(true);
                    getImage(e)
                        .then((result) => {
                            setViewImage(result);
                        })
                        .catch(error => console.log(error));
                    break;
                case "pdf":
                    setContent(e.target.files[0].name);
                    setFilepdf(e.target.files[0]);
                    setTagPdf(true);
                    getPdf(e)
                        .then((result) => {
                            setViewPdf(result);
                        })
                        .catch(error => console.log(error));
                    break;
                default:
                    setContent(e.target.value);
                    setTagImage(false);
                    setTagPdf(false);
                    if(textareaRef) {
                        textareaRef.current.style.height = "0px";
                        const scrollHeight = textareaRef.current.scrollHeight;
                        textareaRef.current.style.height = scrollHeight + "px";
                    }

            }
        }

        const handleTagChange = (e) => {
            setTag(e.target.value);
            switch(e.target.value) {
                case "image":
                    setTagImage(true);
                    setTagPdf(false);
                    break;
                case "pdf":
                    setTagImage(false);
                    setTagPdf(true);
                    break;
                default:
                    setTagImage(false);
                    setTagPdf(false);
            }
        }



        const style = {
            "width": "300px",
            "minHeight": "160px",
            "height": '420px',
            "borderRadius": "5px",
            "padding": "20px",
            "marginTop": "20px",
            "marginBottom": "20px",
            "background": "silver",
            "color": "#efefef",
            "fontSize": "8rem"
        }

        return (
            <div style={{width: '100%',margin: '0px',padding: '0px'}} className='container-fluid mt-2'>
                <span>Cover page</span>
                <table style={{width: '100%',margin: '0px',padding: '0px',top: '0px'}}>
                    <thead>
                        <tr style={{textAlign: 'center'}}>
                            <th>Id</th>
                            <th>Title</th>
                            <th>Cover</th>
                            <th>Submit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input type="text" className="form-control" id="inputGroupFile01" onChange={(e) => {setId(e.target.value)}} value={id} />
                            </td>
                            <td>
                                <input type="text" className="form-control" id="inputGroupFile01" placeholder='Post title' onChange={(e) => handleTitleChange(e)} />
                            </td>
                            <td>
                                <input type="file" accept='image/*' className="form-control" id="inputGroupFile01" title="Image" onChange={((e) => onCoverChange(e))} />
                            </td>
                            <td>
                                <button className="btn btn-outline-secondary" htmlFor="inputGroupFile01" onClick={handlePostTitle}>Upload</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span>Content</span>
                <table style={{width: '100%',margin: '0px',padding: '0px'}}>
                    <thead>
                        <tr style={{textAlign: 'center'}}>
                            <th>id</th>
                            <th>tag</th>
                            <th>link</th>
                            <th>Submit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label className="input-group-text" >{id}</label>
                            </td>
                            <td>
                                <select name="tag" className="input-group-text" value={tag} onChange={(e) => {handleTagChange(e)}}>
                                    {TagList.map((val,index) => {return (<option key={index} value={val}>{val}</option>)})}
                                </select>
                            </td>
                            <td>
                                <select name="link" className="input-group-text" value={link} onChange={(e) => {setLink(e.target.value)}}>
                                    {listContent.map((val,key) => {return (<option key={key} value={key}>{key}</option>)})}
                                </select>
                            </td>
                            <td>
                                <button type="button" className="btn btn-outline-secondary" onClick={handlePostContent}>Upload</button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='4'>
                                {((tag === 'pdf') || (tag === 'image')) ?
                                    <input
                                        type='file'
                                        className="form-control"
                                        accept={(tag === 'pdf') ? 'application/pdf' : 'image/png, image/jpeg, image/jpg'}
                                        onChange={(e) => {handleContentChange(e)}}
                                    /> :
                                    <textarea ref={textareaRef} className="form-control" style={defaultStyle} placeholder={tag} value={content} onChange={(e) => {handleContentChange(e)}}></textarea>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                {tagImage && <>
                                    <div>
                                        <img src={viewImage} alt="" style={style} />
                                    </div>
                                </>}
                            </td>
                            <td colSpan='2'>
                                {tagPdf && <>
                                    <div style={style}>
                                        {viewPdf && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                                            <Viewer fileUrl={viewPdf} />
                                        </Worker></>}
                                        {/* {!viewPdf && <>No pdf file selected</>} */}
                                    </div>
                                </>}

                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>

        )
    }

    const PostEdit = (props) => {

        const ids = props.ids;
        const code = props.code;

        const [tag,setTag] = useState();
        const [content,setContent] = useState('');
        const [link,setLink] = useState(0);

        // const [error,setError] = useState(null);
        // const [success,setSuccess] = useState(null);

        const fileType = ['application/pdf'];

        const [filePdf,setFilePdf] = useState(null);
        const [viewPdf,setViewPdf] = useState(null);

        const [fileImg,setFileImg] = useState(null);
        const [viewImage,setViewImage] = useState(null);

        const [tagPdf,setTagPdf] = useState(false);
        const [tagImage,setTagImage] = useState(false);

        const [originImage,setOriginImage] = useState(null);
        const [deleteImage,setDeleteImage] = useState(null);

        const [postContent,setPostContent] = useState([]);

        const [oldCover,setOldCover] = useState('');
        const [cover,setCover] = useState('');
        const [fileCover,setFileCover] = useState(null);
        const [title,setTitle] = useState('');



        const textareaRef = useRef(null);

        const defaultStyle = {
            resize: "none",
            width: "100%",
            height: "0px"
        };

        useEffect(() => {
            if((ids !== 0) && (code !== 0)) {
                Axios.get(`${HOST}/api/post/getcontent/code/${code}`)
                    .then((result) => {
                        setId(result.data[0].id);
                        setTag(result.data[0].tag);
                        setContent(result.data[0].content);
                        setLink(result.data[0].link);
                        if(result.data[0].tag === 'image') {
                            setViewImage(`${HOST}/post/` + result.data[0].content);
                            setOriginImage(result.data[0].content);
                            setTagImage(true);
                        }
                        if(result.data[0].tag === 'pdf') {
                            setViewPdf(`${HOST}/post/` + result.data[0].content);
                            setOriginImage(result.data[0].content);
                            setTagPdf(true);
                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });

                Axios.get(`${HOST}/api/post/getcontent/id/${ids}`)
                    .then((result) => {
                        setPostContent(result.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            if((ids !== 0) && (code === 0)) {
                Axios.get(`${HOST}/api/post/getlist/id/${ids}`)
                    .then((result) => {
                        setTitle(result.data[0].title);
                        setCover(result.data[0].cover);
                        setOldCover(result.data[0].cover);
                        setViewImage(`${HOST}/post/cover/` + result.data[0].cover);
                        setTagImage(true);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

        },[code,ids]);


        const getImage = (event) => {
            return new Promise((resolve,reject) => {
                if(event.target.files && event.target.files[0]) {
                    let img = window.URL.createObjectURL(event.target.files[0]);
                    resolve(img);
                } else {
                    console.log(console.error());
                    reject();
                }
            });
        };


        const getPdf = (e) => {
            return new Promise((resolve,reject) => {
                let selectedFile = e.target.files[0];
                if(selectedFile) {
                    if(selectedFile && fileType.includes(selectedFile.type)) {
                        let reader = new FileReader();
                        reader.readAsDataURL(selectedFile);
                        reader.onloadend = (e) => {
                            resolve(e.target.result);
                        };
                    }
                    else {
                        reject();
                    }
                }
                else {
                    console.log('select your file');
                }
            });
        };

        const handleContentChange = (e) => {
            e.preventDefault();
            setFileImg(null);
            setFilePdf(null);
            setDeleteImage(originImage);
            switch(tag) {
                case "image":
                    setContent(e.target.files[0].name);
                    setFileImg(e.target.files[0]);
                    setTagImage(true);
                    getImage(e)
                        .then((result) => {
                            setViewImage(result);
                        })
                        .catch(error => console.log(error));
                    break;
                case "pdf":
                    setContent(e.target.files[0].name);
                    setFilePdf(e.target.files[0]);
                    setTagPdf(true);
                    getPdf(e)
                        .then((result) => {
                            setViewPdf(result);
                        })
                        .catch(error => console.log(error));
                    break;
                default:
                    setContent(e.target.value);
                    setTagImage(false);
                    setTagPdf(false);
                    if(textareaRef) {
                        textareaRef.current.style.height = "0px";
                        const scrollHeight = textareaRef.current.scrollHeight;
                        textareaRef.current.style.height = scrollHeight + "px";
                    }

            }
        }

        const uploadImage = async () => {
            try {
                const formData = new FormData();
                formData.append("image",fileImg);
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    }
                };
                const result = await Axios.post(`${HOST}/api/post/upimg`,formData,config);
                console.log("Result of fileImg upload: ",result);
            } catch(error) {
                console.error(error);
            }
        };

        const uploadPdf = async () => {
            try {
                const formData = new FormData();
                formData.append("pdffile",filePdf);
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    }
                };
                const result = await Axios.post(`${HOST}/api/post/uppdf`,formData,config);
                console.log("Result of filePdf upload: ",result);
            } catch(error) {
                console.error(error);
            }
        };



        const handleUpdateContent = () => {

            if(content !== '') {
                Axios.post(`${HOST}/api/post/updatecontent/${code}/${deleteImage}`,{id: id,tag: tag,content: content,link: link}).then(response => {
                }).catch(error => {
                    if(error.response.status === 406 || error.response.status === 409) {setError(error.response.data.message)}
                    else {setError("Something went wrong. Please try again later.")}
                });
                if(fileImg) {
                    uploadImage();
                }
                if(filePdf) {
                    uploadPdf();
                }
                setKey((k) => k + 1);
                setSuccess('The content has been updated!');
                setContent('');
            }
        };

        const style = {
            "width": "100%",
            "minHeight": "160px",
            "height": "auto",
            "borderRadius": "5px",
            "padding": "10px",
            "background": "silver",
            "color": "#efefef",
            "fontSize": "8rem"
        }

        const handleTitleChange = (e) => {
            setTitle(e.target.value);
        }

        const handleCoverChange = (event) => {
            setFileCover(event.target.files[0]);
            setCover(event.target.files[0].name);
            setTagImage(true);
            getImage(event)
                .then((result) => {
                    setViewImage(result);
                })
                .catch(error => console.log(error));
        }

        const handleUpdateTitle = () => {
            if((title !== '') && (cover !== '')) {
                Axios.post(`${HOST}/api/post/update_title/${id}`,{title: title,cover: cover,oldCover: oldCover}).then(response => {
                }).catch(error => {
                    if(error.response.status === 406 || error.response.status === 409) {setError(error.response.data.message)}
                    else {setError("Something went wrong. Please try again later.")}
                });

                if((cover !== oldCover) && (fileCover)) {
                    uploadCover();
                    setSuccess('The Cover has been updated!');
                } else {
                    setSuccess('The Cover has no change!');
                }
                setKey((k) => k + 1);
                setSuccess('The Tite has been updated!');
            }
        }

        const uploadCover = async () => {
            try {
                const formData = new FormData();
                formData.append('image',fileCover);
                const config = {
                    headers: {
                        'accept': 'application/json',
                        'Accept-Language': 'en-US,en;q=0.8',
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                    }
                };
                const result = await Axios.post(`${HOST}/api/post/up_cover`,formData,config);
                console.log("Result of Cover upload: ",result);
            } catch(error) {
                console.error(error);
            }
        };


        return (
            <div style={{width: '100%',margin: '0px',padding: '0px'}} className='container-fluid mt-2'>
                <span>Cover pave</span>
                <table style={{width: '100%',margin: '0px',padding: '0px',top: '0px'}}>
                    <thead>
                        <tr style={{textAlign: 'center'}}>
                            <th>Id</th>
                            <th>Title</th>
                            <th>Cover</th>
                            <th>Submit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label className="input-group-text" >{id}</label>
                            </td>
                            <td>
                                <input type="tetx" className="form-control" value={title} onChange={(e) => {handleTitleChange(e)}} />
                            </td>
                            <td>
                                <input type="file" accept='image/*' className="form-control" id="inputGroupFile01" title="Image" onChange={((e) => handleCoverChange(e))} />
                            </td>
                            <td>
                                <button className="btn btn-outline-secondary" htmlFor="inputGroupFile01" onClick={handleUpdateTitle}>Update</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <span>Content</span>
                <table style={{width: '100%',margin: '0px',padding: '0px'}}>
                    <thead>
                        <tr style={{textAlign: 'center'}}>
                            <th>id</th>
                            <th>tag</th>
                            <th>link</th>
                            <th>Submit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <label className="input-group-text" >{id}</label>
                            </td>
                            <td>
                                <select name="tag" className="input-group-text" value={tag} onChange={(e) => {setTag(e.target.value)}}>
                                    {TagList.map((val,index) => {return (<option key={index} value={val}>{val}</option>)})}
                                </select>
                            </td>
                            <td>
                                <select name="link" className="input-group-text" value={link} onChange={(e) => {setLink(e.target.value)}}>
                                    {postContent.map((val,key) => {return (<option key={key} value={key}>{key}</option>)})}
                                </select>
                            </td>
                            <td>
                                <button type="button" className="btn btn-outline-secondary" onClick={handleUpdateContent}>Update</button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='4'>
                                {((tag === 'pdf') || (tag === 'image')) ?
                                    <input
                                        type='file'
                                        className="form-control"
                                        accept={(tag === 'pdf') ? 'application/pdf' : 'image/*'}
                                        onChange={handleContentChange}
                                    /> :
                                    <textarea ref={textareaRef} className="form-control" style={defaultStyle} placeholder={tag} value={content} onChange={(e) => {handleContentChange(e)}}></textarea>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td colSpan='2'>
                                {tagImage && <>
                                    <div>
                                        <img src={viewImage} alt="" style={style} />
                                    </div>
                                </>}
                            </td>
                            <td colSpan='2'>
                                {tagPdf && <>
                                    <div style={{height: "862px"}}>
                                        {viewPdf && <>
                                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                                                <Viewer
                                                    fileUrl={viewPdf}
                                                />
                                            </Worker>
                                        </>}
                                    </div>
                                </>}
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        )

    }


    const createNewPost = () => {
        Axios.get(`${HOST}/api/post/getlist`)
            .then((result) => {
                const listTitle = (result.data);
                if(listTitle) {
                    setId((listTitle.length) + 1);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    return (
        <div id='page' className='container-fluid mt-5 mt-2'>
            <Navigation page="" />
            <table>
                <thead>
                    <tr style={{textAlign: 'center'}}>
                        <th>Input data</th>
                        <th>Preview</th>
                    </tr>
                </thead>
                <tbody>
                    <tr valign="top">
                        <td>
                            <table style={{width: '100%',margin: '0px',padding: '0px'}}>
                                <thead style={{textAlign: 'center'}}>
                                    <tr>
                                        <td>
                                            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => createNewPost(id))}>New Post</button>
                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-outline-secondary btn-sm" >New Post</button>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan='2'><h2>Upload</h2></td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2'>
                                            <PostUpload />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2'><h2>Edit</h2></td>
                                    </tr>
                                    <tr>
                                        <td colSpan='2' >
                                            <PostEdit ids={ids} code={code} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            {/* {error && <><small style={{color: 'red'}}>{error}</small><br /></>}
                            {success && <><small style={{color: 'blue'}}>{success}</small><br /></>} */}
                        </td>
                        <td>
                            <div style={{overflow: 'scroll',height: '90vh'}}>
                                {id > 0 ? <PostPreview id={id} key={key} /> : <></>}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}


