import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";


import Homes from './pages/Homes';
import Home from './pages/Home';
import HomeUpload from './pages/HomeUpload';

import Art from './pages/Art';
import MathPage from './pages/MathPage';
import CatalogueUpload from './login/CatalogueUpload';
import Catalogues from './pages/Catalogues';
import Catalogue from './pages/Catalogue';
import Admin from './login/Admin';
import Login from './login/Login';
import Manage from './login/Manage';

import PostManager from './posts/PostManager';
import PostsEdit from './posts/PostsEdit';
import PostEdit from './posts/PostEdit';
import PostContentEdit from './posts/PostContentEdit';
import Posts from './posts/Posts';
import Post from './posts/Post';




const App = () => {
  return (
    <div>
      {/* <Header /> */}
      {/* <Navigation /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element=<Homes /> />
          <Route path="home/*" element=<Home /> />
          <Route path="homeupload/*" element=<HomeUpload /> />


          <Route path="catalogues/*" element=<Catalogues /> />
          <Route path="readcatalogue/*" element=<Catalogue /> />
          <Route path="catalogueupload/*" element=<CatalogueUpload /> />


          <Route path="mathpage/*" element=<MathPage /> />

          <Route path="art/*" element=<Art /> />

          <Route path="admin/*" element=<Admin /> />
          <Route path="login/*" element=<Login /> />
          <Route path="manage/*" element=<Manage /> />

          <Route path="posts/*" element=<Posts /> />
          <Route path="post/*" element=<Post /> />
          <Route path="postsedit/*" element=<PostsEdit /> />
          <Route path="postmanager/*" element=<PostManager /> />
          <Route path="postedit/*" element=<PostEdit /> />
          <Route path="contentedit/*" element=<PostContentEdit /> />

        </Routes>
      </BrowserRouter>
    </div>
  )
}
export default App;

