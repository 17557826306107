import React,{useEffect,useState,useRef,useCallback} from "react";
import Axios from "axios";
import HTMLFlipBook from "react-pageflip";
import {pdfjs,Document,Page as ReactPdfPage} from "react-pdf";
import {useLocation} from 'react-router-dom';
import Navigation from "../components/Navigation";
import Constant from "../config/Constant";
import "../css/FlipCatalogue.css";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

//import samplePDF from "../pdf/324_SDS24NI_CATALOUGE.pdf";
const HOST = Constant.HOST;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const pageList = [1];



// const PageCover = React.forwardRef((props,ref) => {
//     return (
//         <div className="page page-cover" ref={ref} data-density="hard">
//             <div className="page-content">
//                 <h2>{props.children}</h2>
//             </div>
//         </div>
//     );
// });

//console.log("Test sample file: ",samplePDF);



const Page = React.forwardRef((props,ref) => {
    return (
        <div className="page" ref={ref}>
            <div className="page-content">
                {/* <div className="page-image"></div> */}
                <ReactPdfPage className="page-text" pageNumber={props.number} />
                {/* <div className="page-text">{props.children}</div> */}
                {/* <div className="page-footer">{props.number + 1}</div> */}
            </div>
        </div>
    );
});

function FlipCatalogue(props) {
    const {state} = useLocation();
    const {id,pages} = state;
    const totalPages = pages;
    const book = useRef();
    const [numPages,setNumPages] = useState();
    const [pageNumber,setPageNumber] = useState(1);
    const [url,setUrl] = useState();


    useEffect(() => {
        Axios.get(`${HOST}/api/catalogue/get/id/${id}`).then((result) => {
            console.log(result.data[0]);
            setUrl(`${HOST}/catalogue/` + result.data[0].link)
        },[id])
    });

    const nextButtonClick = () => {
        book.current.pageFlip().flipNext();
    };

    const prevButtonClick = () => {
        book.current.pageFlip().flipPrev();
    };

    const onFlip = useCallback((e) => {
        setPageNumber(e.data + 1);
    },[]);

    function onBookInit() {
        for(let i = 2; i < totalPages; i++) {
            pageList.push(i)
        }
        setNumPages(totalPages);
    }

    return (
        <div>
            <Navigation page="" />
            <hr className="featurette-divider mt-5" />
            <div className="container">
                {/* {postList.map((val,key) => {
                    const url = (`${HOST}/catalogue/` + (val.link));
                    return ( */}
                <Document file={url} onLoadError={console.error} >
                    <HTMLFlipBook
                        width={640}
                        height={900}
                        size="fixed"
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1533}
                        maxShadowOpacity={0.5}
                        showCover={true}
                        mobileScrollSupport={true}
                        onFlip={onFlip}
                        onInit={onBookInit}
                        // onChangeOrientation={onChangeOrientation}
                        // onChangeState={onChangeState}
                        className="demo-book"
                        ref={book}
                    >
                        {/* <PageCover>BOOK TITLE</PageCover> */}
                        {pageList.map((val,key) => {
                            return (
                                <Page key={key} number={val} />
                            );
                        })}

                        {/* <PageCover>THE END</PageCover> */}

                    </HTMLFlipBook>
                </Document>
                {/* )
                })} */}
                <div className="container">
                    <hr className="featurette-divider mt-2" />
                    <div className="row justify-content-evenly">
                        <div className="col-3">
                        </div>
                        <div className="col-2">
                            <button className="btn btn-secondary btn-sm" type="button" onClick={prevButtonClick}>
                                Previous
                            </button>
                        </div>
                        <div className="col-2">
                            [<span> {pageNumber}</span> of <span>{numPages} </span>]
                        </div>
                        <div className="col-2">
                            <button className="btn btn-secondary btn-sm" type="button" onClick={nextButtonClick}>
                                Next
                            </button>
                        </div>
                        <div className="col-3">
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}


export default FlipCatalogue;
