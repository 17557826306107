import React,{useState} from 'react'
import Axios from 'axios'
import {useNavigate} from "react-router-dom";
import Constant from "../config/Constant";

const HOST = Constant.HOST;

const PostRead = (props) => {
    const [like,setLike] = useState(0);
    const navigate = useNavigate();


    const increaseLike = (id,likes) => {
        Axios.post(`${HOST}/api/post/getlist/like/${id}`).then((response) => {
            alert("you liked a post")
        });

        like > likes ? setLike(like + 1) : setLike(likes + 1);
        navigate('/post',{state: {id: props.id,title: props.title}})
    };

    return (
        <div>
            <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => increaseLike(props.id,props.likes))}>
                Read more <span className="badge text-bg-secondary">{like > props.likes ? like : props.likes}</span>
            </button>
        </div>
    );
};

export default PostRead;