import React,{useState} from 'react';
import Axios from 'axios';
import Constant from '../config/Constant';
import Navigation from "../components/Navigation";

const HOST = Constant.HOST;

function HomeUpload() {

    const [title,setTitle] = useState("");
    const [context,setContext] = useState("");
    const [author,setAuthor] = useState("");
    const [image,setImage] = useState(null);
    const [file,setFile] = useState(null);


    const submitPost = () => {
        Axios.post(`${HOST}/api/create`,{title: title,context: context,author: author});
        if(file) {
            //addImage();
            uploadImage();
        }
    }


    const uploadImage = async () => {
        try {
            const formData = new FormData();
            formData.append("image",file);
            console.log("Upload image file name",file);
            //formData.append("destination", "images");
            //formData.append("create_thumbnail", true);
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                }
            };

            const result = await Axios.post(`${HOST}/api/create/image`,formData,config);
            console.log("Result: ",result);
        } catch(error) {
            console.error(error);
        }
    };



    const getImage = (event) => {

        return new Promise((resolve,reject) => {
            if(event.target.files && event.target.files[0]) {
                let img = window.URL.createObjectURL(event.target.files[0]);
                resolve(img);
                console.log("Img data URL",img);
            } else {
                console.log(console.error());
                reject();
            }

        });

    };

    const onImageChange = (event) => {
        setFile(event.target.files[0])
        getImage(event)
            .then((result) => {
                setImage(result);
                console.log("Image data change",image);
            })
            .catch(error => console.log(error));
    }


    return (

        <div className='container mt-5'>
            <Navigation page="UploadIMG" />
            <form className='form-group mt-4' >
                <br></br>
                <label>Title:</label>
                <input type="text" className='form-control' onChange={(e) => {setTitle(e.target.value)}} />
                <div className="row featurette">
                    <div className="col-md-6">
                        <label>Context:</label>
                        <textarea className='form-control' style={{"frameBorder": 0,"scrolling": "auto","height": "320px","width": "100%"}} onChange={(e) => {setContext(e.target.value)}}></textarea>
                    </div>
                    <div className="col-md-6">
                        <label>Author: </label>
                        <input type="text" className='form-control' onChange={(e) => {setAuthor(e.target.value)}} />
                        <img src={image} alt="" style={{
                            "width": "100%",
                            "minHeight": "320px",
                            "height": 'auto',
                            "borderRadius": "5px",
                            "padding": "20px",
                            "marginTop": "20px",
                            "marginBottom": "20px",
                            "background": "silver",
                            "color": "#efefef",
                            "fontSize": "8rem"
                        }} />
                        <br></br>
                        <input type="file" className='form-control' onChange={((e) => onImageChange(e))} />
                        <button onClick={submitPost} className='btn btn-success btn-lg mt-2'>UPLOAD</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default HomeUpload;
