import React,{useState,useEffect} from "react";
import {useForm} from 'react-hook-form';
import Axios from "axios";
import Navigation from "../components/Navigation";
import PostNav from "../posts/PostNav";
import "../css/HomePage.css";
import Constant from "../config/Constant";

const HOST = Constant.HOST;

function PostsEdit() {
    const [update,setUpdate] = useState(0);

    const PostView = (props) => {
        const update = props.update;
        const {register} = useForm();
        const [postList,setPostList] = useState([]);
        const [search,setSearch] = useState(null);

        useEffect(() => {
            Axios.get(`${HOST}/api/post/getlist`)
                .then((result) => {
                    setPostList(result.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        },[update]);

        function searchPostList(search) {
            Axios.get(`${HOST}/api/post/getlist/${search}`).then((result) => {
                setPostList(result.data);
            });
        }

        return (
            <>
                <div className="row mt-5">
                    <div className="col-md-6 offset-md-3">
                        <div className="input-group">
                            <input className="form-control" type="text" placeholder="Search context..." {...register("Search",{required: false,maxLength: 80})}
                                onChange={(e) => {setSearch(e.target.value)}}
                            />
                            <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={(() => searchPostList(search))}>Search</button>
                        </div>
                    </div>
                </div>
                <div id="columns">
                    {postList.map((val,index) => {
                        return (
                            <figure key={index}>
                                <img src={`${HOST}/post/cover/` + val.cover} alt="" />
                                <h5 className="card-title">{val.title}</h5>
                                <PostNav id={val.id} likes={val.likes} title={val.title} />
                                <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeletePost(val.id,val.cover))}>
                                    Delete
                                </button>
                            </figure>
                        );
                    })}
                </div>
            </>
        )
    };


    // const deletePost = (id,cover) => {
    //     return new Promise((resolve,reject) => {
    //         if(window.confirm("Do you want to delete this post?")) {
    //             Axios.delete(`${HOST}/api/post/delete/${id}/${cover}`).then((response) => {
    //                 //resolve(response.data.serverStatus)
    //             });
    //         } else {
    //             reject();
    //         }
    //     });
    // };

    // const handleDeletePost = (id,cover) => {
    //     deletePost(id,cover)
    //         .then((result) => {
    //             setUpdate((k) => k + 1);
    //         })
    //         .catch(error => console.log(error));
    // };

    const handleDeletePost = (id,cover) => {
        if(window.confirm("Do you want to delete this post?")) {
            Axios.delete(`${HOST}/api/post/delete/${id}/${cover}`).then((response) => {
                //resolve(response.data.serverStatus)
            });
        }
        setUpdate((k) => k + 1);
    };



    return (
        <div className='container mt-4'>
            <Navigation page="PostEdit" />
            <hr className="featurette-divider" />
            <PostView update={update} />
        </div>
    );
}

export default PostsEdit;
