import React,{useState,useEffect} from "react";
import {useLocation} from 'react-router-dom';
//import {useNavigate} from "react-router-dom";
import Axios from "axios";
import {Viewer} from '@react-pdf-viewer/core'; // install this library
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout'; // install this library
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {Worker} from '@react-pdf-viewer/core'; // install this library

import "../css/MathPage.css";
import Navigation from "../components/Navigation";
import Constant from "../config/Constant";
const HOST = Constant.HOST;

export default function PostContentEdit() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    //const navigate = useNavigate();
    const {state} = useLocation();
    const {idd,code} = state;

    const [id,setId] = useState(1);
    const [tag,setTag] = useState();
    const [content,setContent] = useState('Content');
    const [link,setLink] = useState(0);

    const [error,setError] = useState(null);
    const [success,setSuccess] = useState(null);

    const fileType = ['application/pdf'];
    const [filepdf,setFilepdf] = useState(null);
    const [viewPdf,setViewPdf] = useState(null);
    const [image,setImage] = useState(null);
    const [fileimg,setFileimg] = useState(null);
    const [tagPdf,setTagPdf] = useState(false);
    const [tagImage,setTagImage] = useState(false);

    const [originImage,setOriginImage] = useState(null);
    const [deleteImage,setDeleteImage] = useState(null);

    const [postContent,setPostContent] = useState([]);






    useEffect(() => {
        Axios.get(`${HOST}/api/post/getcontent/code/${code}`)
            .then((result) => {
                setId(result.data[0].id);
                setTag(result.data[0].tag);
                setContent(result.data[0].content);
                setLink(result.data[0].link);
                if(result.data[0].tag === 'image') {
                    setImage(`${HOST}/post/` + result.data[0].content);
                    setOriginImage(result.data[0].content);
                    setTagImage(true);
                }
                if(result.data[0].tag === 'pdf') {
                    setViewPdf(`${HOST}/post/` + result.data[0].content);
                    setOriginImage(result.data[0].content);
                    setTagPdf(true);
                }

            })
            .catch((error) => {
                console.log(error);
            });

        Axios.get(`${HOST}/api/post/getcontent/id/${idd}`)
            .then((result) => {
                setPostContent(result.data);
            })
            .catch((error) => {
                console.log(error);
            });
    },[code,idd]);


    const getImage = (event) => {
        return new Promise((resolve,reject) => {
            if(event.target.files && event.target.files[0]) {
                let img = window.URL.createObjectURL(event.target.files[0]);
                resolve(img);
            } else {
                console.log(console.error());
                reject();
            }
        });
    };


    const getPdf = (e) => {
        return new Promise((resolve,reject) => {
            let selectedFile = e.target.files[0];
            if(selectedFile) {
                if(selectedFile && fileType.includes(selectedFile.type)) {
                    let reader = new FileReader();
                    reader.readAsDataURL(selectedFile);
                    reader.onloadend = (e) => {
                        resolve(e.target.result);
                        //setPdfFileError('');
                    };
                }
                else {
                    //setPdfFileError('Please select valid pdf file');
                    reject();
                }
            }
            else {
                console.log('select your file');
            }
        });
    };

    const handleContenChange = (e) => {
        e.preventDefault();
        setFileimg(null);
        setFilepdf(null);
        setDeleteImage(originImage);
        setSuccess('');
        switch(tag) {
            case "text":
                setContent(e.target.value);
                setTagImage(false);
                setTagPdf(false);
                break;
            case "image":
                setContent(e.target.files[0].name);
                setFileimg(e.target.files[0]);
                setTagImage(true);
                //setDeleteImage(originImage);
                getImage(e)
                    .then((result) => {
                        setImage(result);
                    })
                    .catch(error => console.log(error));
                break;
            case "pdf":
                setContent(e.target.files[0].name);
                setFilepdf(e.target.files[0]);
                setTagPdf(true);
                //setDeleteImage(originImage);
                getPdf(e)
                    .then((result) => {
                        setViewPdf(result);
                    })
                    .catch(error => console.log(error));
                break;
            default:
                setContent(e.target.value);
                setTagImage(false);
                setTagPdf(false);

        }
    }

    const uploadImage = async () => {
        try {
            const formData = new FormData();
            formData.append("image",fileimg);
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                }
            };
            const result = await Axios.post(`${HOST}/api/post/upimg`,formData,config);
            console.log("Result of fileImg upload: ",result);
        } catch(error) {
            console.error(error);
        }
    };

    const uploadPdf = async () => {
        try {
            const formData = new FormData();
            formData.append("pdffile",filepdf);
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                }
            };
            const result = await Axios.post(`${HOST}/api/post/uppdf`,formData,config);
            console.log("Result of filePdf upload: ",result);
        } catch(error) {
            console.error(error);
        }
    };



    const handleUpdateContent = () => {

        Axios.post(`${HOST}/api/post/updatecontent/${code}/${deleteImage}`,{id: id,tag: tag,content: content,link: link}).then(response => {
        }).catch(error => {
            if(error.response.status === 406 || error.response.status === 409) {setError(error.response.data.message)}
            else {setError("Something went wrong. Please try again later.")}
        });
        if(fileimg) {
            uploadImage();
        }
        if(filepdf) {
            uploadPdf();
        }
        setSuccess('The content has been update!');
        setContent('');
    };

    const style = {
        "width": "auto",
        "minHeight": "160px",
        "height": "auto",
        "borderRadius": "5px",
        "padding": "10px",
        "background": "silver",
        "color": "#efefef",
        "fontSize": "8rem"
    }

    return (
        <div className='container mt-5'>
            <Navigation page="" />
            {error && <><small style={{color: 'red'}}>{error}</small><br /></>}
            {success && <><small style={{color: 'blue'}}>{success}</small><br /></>}
            <div className="input-group">
                <label className="input-group-text" >{id}</label>
                <select name="tag" className="input-group-text" value={tag} onChange={(e) => {setTag(e.target.value)}}>
                    <option value="section">Section</option>
                    <option value="head1">Head 1</option>
                    <option value="head2">Head 2</option>
                    <option value="text">Text</option>
                    <option value="mark">Mark</option>
                    <option value="code">Code</option>
                    <option value="image">Image</option>
                    <option value="pdf">Pdf</option>
                    <option value="math">Math</option>
                </select>
                {((tag === 'pdf') || (tag === 'image')) ?
                    <input
                        type='file'
                        className="form-control"
                        accept={(tag === 'pdf') ? 'application/pdf' : 'image/png, image/jpeg, image/jpg'}
                        onChange={handleContenChange}
                    /> :
                    <textarea className="form-control" style={{height: '90vh'}} placeholder={tag} value={content} onChange={handleContenChange}></textarea>
                }
                <select name="link" className="input-group-text" value={link} onChange={(e) => {setLink(e.target.value)}}>
                    {postContent.map((val,key) => {return (<option key={key} value={key}>{key}</option>)})}
                </select>
                <button type="button" className="btn btn-outline-secondary" onClick={handleUpdateContent}>Update</button>
            </div>
            <br></br>
            <div>
                {tagImage && <>
                    <div>
                        <img src={image} alt="" style={style} />
                    </div>
                </>}
                {tagPdf && <>
                    <div style={{height: "862px"}}>
                        {viewPdf && <>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                                <Viewer
                                    fileUrl={viewPdf}
                                    plugins={[defaultLayoutPluginInstance]}
                                />
                            </Worker>
                        </>}
                        {/* {!viewPdf && <>No pdf file selected</>} */}
                    </div>
                </>}
            </div>

        </div>
    )

}