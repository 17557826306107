import React,{useState} from 'react';
import {useForm} from "react-hook-form";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import "../css/Login.module.css";
import {setUserSession} from '../Utils/Common';
import Constant from "../config/Constant";

const HOST = Constant.HOST;

function Login(props) {

  const navigate = useNavigate();

  const {register,handleSubmit,formState: {errors}} = useForm();

  const [loading,setLoading] = useState(false);
  const [error,setError] = useState(null);

  const onSubmit = (data) => {
    setError(null);
    setLoading(true);
    axios.post(`${HOST}/users/signin`,data).then(response => {
      setLoading(false);
      setUserSession(response.data.token,response.data.user);
      navigate('/manage')
    }).catch(error => {
      setLoading(false);
      if(error.response.status === 401 || error.response.status === 400) {setError(error.response.data.message)}
      else {setError("Something went wrong. Please try again later.")}
    });
    // reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="email">email</label>
      <input
        id="email"
        {...register("email",{
          required: "required",
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: "Entered value does not match email format"
          }
        })}
        type="email"
        autoComplete="username"
      />
      {errors.email && <span role="alert">{errors.email.message}</span>}

      <label htmlFor="password">password</label>
      <input
        id="password"
        {...register("password",{
          required: "required",
          minLength: {
            value: 5,
            message: "min length is 5"
          }
        })}
        type="password"
        autoComplete="current-password"
      />
      {errors.password && <span role="alert">{errors.password.message}</span>}

      {error && <><small style={{color: 'red'}}>{error}</small><br /></>}<br />
      <button type="submit" disabled={loading}>Login</button>

    </form>
  );
}

export default Login;