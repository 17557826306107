import React,{useState,useEffect} from "react";
import {useForm} from 'react-hook-form';
import Axios from "axios";
import {Buffer} from "buffer";
import Navigation from "../components/Navigation";
import HomeRead from "../components/HomeRead";
import "../css/HomePage.css";
import Constant from "../config/Constant";

const HOST = Constant.HOST;

function Home() {
  const {register} = useForm();
  const [search,setSearch] = useState(null);
  const [postList,setPostList] = useState([]);


  useEffect(() => {
    Axios.get(`${HOST}/api/get`)
      .then((result) => {
        setPostList(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },[]);

  function searchPostList(search) {
    Axios.get(`${HOST}/api/get/${search}`).then((result) => {
      setPostList(result.data);
    });
  }

  return (
    <div className='container mt-4'>
      <Navigation page="Home" />
      <hr className="featurette-divider" />
      <div className="row mt-5">
        <div className="col-md-6 offset-md-3">
          <div className="input-group">
            <input className="form-control" type="text" placeholder="Search context..." {...register("Search",{required: false,maxLength: 80})}
              onChange={(e) => {setSearch(e.target.value)}}
            />
            <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={(() => searchPostList(search))}>Search</button>
          </div>
        </div>
      </div>
      <div id="columns">
        {postList.map((val,key) => {
          return (
            <figure key={val.id}>
              <img src={val.link ? "data:image/png;base64," + new Buffer(val?.link,"binary").toString("base64") : null} alt="" />
              <h5 className="card-title">{val.title}</h5>
              <figcaption>
                {val.context.length > 300 ? val.context.substring(0,300) + " ..." : val.context}
              </figcaption>
              <HomeRead id={val.id} likes={val.likes} />
            </figure>
          );
        })}
      </div>
    </div>
  );
}

export default Home;
