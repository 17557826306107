import React,{useState} from 'react';
import Axios from 'axios';
import {Viewer} from '@react-pdf-viewer/core';
//import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import {Worker} from '@react-pdf-viewer/core';

import Constant from '../config/Constant';
import Navigation from "../components/Navigation";

const HOST = Constant.HOST;

function UpCatalogue() {

    //const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [pdfFileError,setPdfFileError] = useState('');
    const [filepdf,setFilepdf] = useState(null);
    const [viewPdf,setViewPdf] = useState(null);
    const [pages,setPages] = useState(1);
    const fileType = ['application/pdf'];
    const [tagPdf,setTagPdf] = useState(false);
    const [tagImage,setTagImage] = useState(false);
    const [image,setImage] = useState(null);
    const [fileimg,setFileimg] = useState(null);
    const [title,setTitle] = useState("");
    const [success,setSuccess] = useState(null);

    const uploadPdf = async () => {
        try {
            const formData = new FormData();
            formData.append("pdffile",filepdf);
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                }
            };
            const result = await Axios.post(`${HOST}/api/catalogue/uppdf`,formData,config);
            console.log("Result of filePdf upload: ",result);
        } catch(error) {
            console.error(error);
        }
    };


    const getPdf = (e) => {
        return new Promise((resolve,reject) => {
            let selectedFile = e.target.files[0];
            if(selectedFile) {
                if(selectedFile && fileType.includes(selectedFile.type)) {
                    let reader = new FileReader();
                    reader.readAsDataURL(selectedFile);
                    reader.onloadend = (e) => {
                        resolve(e.target.result);
                        setPdfFileError('');
                    };
                }
                else {
                    setPdfFileError('Please select valid pdf file');
                    reject();
                }
            }
            else {
                console.log('select your file');
            }
        });
    };


    const handlePdfFileChange = (e) => {
        e.preventDefault();
        let selectedFile = e.target.files[0];
        setFilepdf(selectedFile);
        setTagPdf(true);
        getPdf(e)
            .then((result) => {
                setViewPdf(result);
            })
            .catch(error => console.log(error));
    }

    const uploadImage = async () => {
        try {
            const formData = new FormData();
            formData.append("image",fileimg);
            //console.log("Upload image file name",fileimg);
            const config = {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                }
            };
            const result = await Axios.post(`${HOST}/api/catalogue/upimg`,formData,config);
            console.log("Result of fileImg upload: ",result);
        } catch(error) {
            console.error(error);
        }
    };

    const getImage = (event) => {
        return new Promise((resolve,reject) => {
            if(event.target.files && event.target.files[0]) {
                let img = window.URL.createObjectURL(event.target.files[0]);
                resolve(img);
                //console.log("Img data URL",img);
            } else {
                console.log(console.error());
                reject();
            }
        });
    };

    const onImageChange = (event) => {
        setFileimg(event.target.files[0])
        setTagImage(true);
        getImage(event)
            .then((result) => {
                setImage(result);
            })
            .catch(error => console.log(error));
    }

    const handlePdfFileSubmit = (e) => {
        e.preventDefault();
        Axios.post(`${HOST}/api/catalogue`,{title: title,pages: pages});
        if(filepdf) {
            uploadPdf();
            uploadImage();
        }
        setSuccess('The catalogue has been created!');
    };

    const handleDocumentLoad = (e) => {
        setPages(e.doc.numPages);
        console.log('NumPage:',e.doc.numPages)
    };

    const style = {
        "width": "300px",
        "minHeight": "160px",
        "height": '420px',
        "borderRadius": "5px",
        "padding": "20px",
        "marginTop": "20px",
        "marginBottom": "20px",
        "background": "silver",
        "color": "#efefef",
        "fontSize": "8rem"
    }


    return (
        <div className='container mt-5'>
            <Navigation page="UploadCatalogue" />
            <br></br>
            {success && <><small style={{color: 'blue'}}>{success}</small><br /></>}
            <form className='form-group' onSubmit={handlePdfFileSubmit}>
                <input type="text" className='form-control' placeholder='Catalogue title'
                    onChange={(e) => {setTitle(e.target.value)}}
                />
                <input type="file" className='form-control' accept='image/png, image/jpeg, image/jpg' onChange={((e) => onImageChange(e))} />
                <div className="input-group mb-3">
                    <input type="file" className="form-control" accept='application/pdf' required onChange={handlePdfFileChange} />
                    <button type="button" onClick={handlePdfFileSubmit} className="btn btn-outline-secondary">Upload</button>
                </div>
                {pdfFileError && <div className='error-msg'>{pdfFileError}</div>}
                <br></br>
            </form>
            <br></br>

            <div id="columns">
                {tagImage && <>
                    <div>
                        <img src={image} alt="" style={style} />
                    </div>
                </>}
                {tagPdf && <>
                    <div style={style}>
                        {viewPdf && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                            <Viewer fileUrl={viewPdf}
                                // plugins={[defaultLayoutPluginInstance]}
                                onDocumentLoad={((e) => handleDocumentLoad(e))}
                            />
                        </Worker></>}
                    </div>
                </>}
            </div>
            {/* <div>
                <img src={image} alt="" style={{
                    "width": "100%",
                    "minHeight": "320px",
                    "height": 'auto',
                    "borderRadius": "5px",
                    "padding": "20px",
                    "marginTop": "20px",
                    "marginBottom": "20px",
                    "background": "silver",
                    "color": "#efefef",
                    "fontSize": "8rem"
                }} />
            </div>
            <div className='pdf-container'>
                {viewPdf && <><Worker workerUrl="https://unpkg.com/pdfjs-dist@3.8.162/build/pdf.worker.min.js">
                    <Viewer fileUrl={viewPdf}
                        plugins={[defaultLayoutPluginInstance]}
                        onDocumentLoad={((e) => handleDocumentLoad(e))}
                    />
                </Worker></>}
                {!viewPdf && <>No pdf file selected</>}
            </div> */}
        </div>
    )
}

export default UpCatalogue;
