import React,{useState,useEffect} from 'react';
import axios from 'axios';

import {getToken,removeUserSession,setUserSession} from '../Utils/Common';
import Constant from "../config/Constant";

const HOST = Constant.HOST;


function Admin() {
    const [authLoading,setAuthLoading] = useState(true);


    useEffect(() => {
        const token = getToken();
        console.log(token)

        if(!token) {
            return;
        }

        axios.get(`${HOST}/verifyToken?token=${token}`).then(response => {
            setUserSession(response.data.token,response.data.user);
            console.log(response.data.user);
            console.log(response.data.token);
            setAuthLoading(false);
        }).catch(error => {
            removeUserSession();
            setAuthLoading(false);
            console.log(error.name);
        });
    },[]);

    if(authLoading && getToken()) {
        return <div className="content">Checking Authentication...</div>
    }

    return (
        <div id="wrapper">
            <nav>
                <h1>Admin</h1>
                <div id="links">
                    <a href="/">Home</a>
                    <a href="/login">Login</a>
                </div>
            </nav>
        </div>
    );
}

export default Admin;
