import {Worker,Viewer} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import Constant from "../config/Constant";
const HOST = Constant.HOST;

export default function PdfViewer(props) {

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    //const fileUrl = (`${process.env.PUBLIC_URL}/pdf/` + props.pdfFile);
    const fileUrl = (`${HOST}/pdf/` + props.pdfFile);
    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
            <div style={{height: "862px"}}>
                <Viewer
                    fileUrl={fileUrl}
                    plugins={[defaultLayoutPluginInstance]}
                />
            </div>
        </Worker>
        // <iframe title="PDF"
        //     src={fileUrl + "#toolbar=1" + "#allowfullscreen=true"}
        //     style={{
        //         "border": "2px solid black",
        //         "scrolling": "auto",
        //         "toolbar": "1",
        //         "marginLeft": "1em",
        //         "height": "850px",
        //         "width": "100%"
        //     }}
        // ></iframe>
    );
}
