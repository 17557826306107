import React,{useState,useEffect} from "react";
import {useLocation} from 'react-router-dom';
import Axios from "axios";
import {MathJax,MathJaxContext} from "better-react-mathjax";
import {Worker,Viewer} from "@react-pdf-viewer/core";
import {defaultLayoutPlugin} from "@react-pdf-viewer/default-layout";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

//import parse from 'html-react-parser';
import "../css/MathPage.css";
import Constant from "../config/Constant";
//import {Buffer} from "buffer";
//import PostLink from "../components/PostLink";

const HOST = Constant.HOST;
export default function Post() {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const config = {
        loader: {load: ["input/asciimath"]}
    };
    const {state} = useLocation();
    const {id,title} = state;
    const [postContent,setPostContent] = useState([]);
    const [refs,setRefs] = useState([]);


    const handleScroll = index =>
        refs[index].current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });


    useEffect(() => {
        Axios.get(`${HOST}/api/post/getcontent/id/${id}`)
            .then((result) => {
                setPostContent(result.data);
                const refs = (result.data).reduce((acc,value,index) => {
                    acc[index] = React.createRef();
                    return acc;
                },{});
                setRefs(refs);
            })
            .catch((error) => {
                console.log(error);
            });
    },[id]);

    const AElement = (props) => {
        var text = props.text;
        const checkA = text.indexOf(`[a]`);
        if(checkA > -1) {
            const aaa = text.split(`[a]`);
            return (
                <>
                    {aaa[0]}
                    <span
                        style={{
                            cursor: 'pointer',
                            color: 'blue',
                            textDecoration: 'underline'
                        }}
                        onClick={() => handleScroll(props.link)}
                    >{aaa[1]}</span>
                    {aaa[2]}
                </>
            )
        } else {
            return (
                <>{text}</>
            )
        }
    }

    const ContentElement = (props) => {
        var text = props.text;
        const checkS = text.indexOf(`[s]`);
        const checkA = text.indexOf(`[a]`);
        if(checkS > -1) {
            const sss = text.split(`[s]`);
            return (
                <>
                    {sss.map((key,val) => {
                        return (
                            ((val % 2 === 0) ?
                                (<AElement key={key} link={props.link} text={sss[val]} />) :
                                (<strong key={key}><AElement link={props.link} text={sss[val]} /></strong>)
                            )
                        )
                    })}
                </>
            )

        } else if(checkA > -1) {
            const aaa = text.split(`[a]`);
            return (
                <>
                    {aaa.map((val,key) => {
                        return (
                            ((key % 2 === 0) ?
                                (<AElement key={key} link={props.link} text={aaa[key]} />) :
                                <span
                                    key={key}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'blue',
                                        textDecoration: 'underline'
                                    }}
                                    onClick={() => handleScroll(props.link)}
                                >{aaa[key]}</span>
                            )
                        )
                    })}
                </>
            )
        } else {
            return (
                <>{text}</>
            )
        }

    }
    const [length,setLength] = useState(1000);
    const [wind,setWind] = useState(1);
    const bite = ((length * wind / 276)).toFixed(2);


    const handleLengthChange = (e) => {
        setLength(e.target.value);
    }

    const handleWindChange = (e) => {
        setWind(e.target.value);
    }




    return (
        <div className='container-lg mt-5'>
            <hr className="featurette-divider" />

            <div style={{'color': 'rgb(80,80,80)','backgroundColor': 'rgb(200,200,200)','lineHeight': 1.3}}>
                <div id="wrapper">
                    <nav>
                        <h1>{title}</h1>
                        <div id="links">
                            <a href="/">Home</a>
                            <a href="/posts">Posts</a>
                        </div>
                    </nav>
                    <article>
                        <section>
                            <MathJaxContext config={config}>
                                {postContent.map((val,key) => {
                                    let filePath = `${HOST}/post/` + val.content;
                                    switch(val.tag) {
                                        case "image":
                                            return (
                                                <div ref={refs[key]} id={val.code} key={key}>
                                                    <img src={filePath} alt="" style={{
                                                        "border": "1px solid black",
                                                        "marginLeft": "1em",
                                                        'marginBottom': '1em',
                                                        "height": "auto",
                                                        "width": "98%"
                                                    }}>
                                                    </img>
                                                </div>

                                            )
                                        case "pdf":
                                            return (
                                                <div ref={refs[key]} id={val.code} key={key}>
                                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                                                        <div style={{height: "790px"}}>
                                                            <Viewer
                                                                fileUrl={filePath}
                                                                plugins={[defaultLayoutPluginInstance]}
                                                            />
                                                        </div>
                                                    </Worker>
                                                    {/* <iframe title="PDF"
                                                        src={`${HOST}/post/` + val.content + "#toolbar=0"}
                                                        style={{
                                                            "border": "2px solid black",
                                                            "scrolling": "auto",
                                                            "toolbar": "1",
                                                            "marginLeft": "1em",
                                                            "height": "90vh",
                                                            "width": "100%"
                                                        }}
                                                    ></iframe> */}
                                                </div>
                                            )
                                        case "math":
                                            return (
                                                <p ref={refs[key]} id={val.code} key={key}><MathJax>{val.content}</MathJax>
                                                </p>
                                            )
                                        default:
                                            return React.createElement(
                                                `${val.tag}`,
                                                {
                                                    key: key,
                                                    ref: refs[key]
                                                },
                                                <ContentElement text={val.content} link={val.link} />
                                            );
                                    }
                                })}
                                <div className="input-group mb-3">
                                    <span className="input-group-text">Length(mm)</span>
                                    <input type="text" className="form-control" value={length} onChange={(e) => {handleLengthChange(e)}} />
                                    <span className="input-group-text">Windload(kPa)</span>
                                    <input type="text" className="form-control" value={wind} onChange={(e) => {handleWindChange(e)}} />
                                    <span className="input-group-text">Bite = {bite}mm</span>
                                </div>

                            </MathJaxContext>
                        </section>
                    </article>
                </div>
            </div>
        </div>
    )
}



