import React,{useState,useEffect} from "react";
//import {useForm} from 'react-hook-form';
import Axios from "axios";
import {Buffer} from "buffer";
import {useLocation} from 'react-router-dom';
//import Like from "../components/Like";
//import "../css/ReadMore.css";
import Constant from "../config/Constant";
import Navigation from "../components/Navigation";

const HOST = Constant.HOST;

function Home() {
    const {state} = useLocation();
    const {id} = state;
    const [postList,setPostList] = useState([]);

    useEffect(() => {
        Axios.get(`${HOST}/api/get/id/${id}`).then((result) => {
            setPostList(result.data);
        })
            .catch((error) => {
                console.log(error);
            });
    },[id]);


    return (
        <div>
            <div className='container mt-4'>
                <Navigation page="" />

                {postList.map((val,key) => {
                    return (
                        <main key={val.id}>

                            <div className="container marketing">
                                <h2 className="text-uppercase mt-5 pt-4">{val.title}</h2>
                                <hr className="featurette-divider" />
                                <div className="row featurette">
                                    <div className="col-md-7">
                                        <h3 className="featurette-heading fw-normal lh-1"
                                            style={{
                                                "borderRadius": "5px",
                                                "padding": "10px",
                                                "background": "silver",
                                                "color": "black",
                                            }}>
                                            {val.title}
                                            <span className="text-body-secondary">{val.author}</span>
                                        </h3>
                                        <p className="lead" style={{
                                            "textAlign": "justify",
                                            "textJustify": "inter-word",
                                            "marginLeft": "0px",
                                            "borderRadius": "5px",
                                            "color": "black",
                                        }}>
                                            {val.context}
                                        </p>
                                    </div>
                                    <div className="col-md-5">
                                        <img
                                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                                            style={{
                                                "width": "100%",
                                                "minHeight": "320px",
                                                "height": 'auto',
                                                "borderRadius": "5px",
                                                "padding": "10px",
                                                "marginTop": "0px",
                                                "marginBottom": "20px",
                                                "background": "silver",
                                                "color": "#efefef",
                                                "fontSize": "8rem"
                                            }}
                                            src={val.link ? "data:image/png;base64," + new Buffer(val?.link,"binary").toString("base64") : null}
                                            alt=""
                                            role="img"
                                            aria-label="Placeholder: 500x500"
                                            preserveAspectRatio="xMidYMid slice"
                                            focusable="false"
                                        />
                                    </div>
                                </div>

                                <hr className="featurette-divider" />

                                <div className="row featurette">
                                    <div className="col-md-7 order-md-2">
                                        <h2 className="featurette-heading fw-normal lh-1">
                                            Oh yeah, it’s that good.{" "}
                                            <span className="text-body-secondary">See for yourself.</span>
                                        </h2>
                                        <p className="lead">
                                            Another featurette? Of course. More placeholder content here to
                                            give you an idea of how this layout would work with some actual
                                            real-world content in place.
                                        </p>
                                    </div>
                                    <div className="col-md-5 order-md-1">
                                        <svg
                                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                                            width="500"
                                            height="500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            role="img"
                                            aria-label="Placeholder: 500x500"
                                            preserveAspectRatio="xMidYMid slice"
                                            focusable="false"
                                        >
                                            <title>Placeholder</title>
                                            <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
                                            <text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">
                                                500x500
                                            </text>
                                        </svg>
                                    </div>
                                </div>

                                <hr className="featurette-divider" />

                                <div className="row featurette">
                                    <div className="col-md-7">
                                        <h2 className="featurette-heading fw-normal lh-1">
                                            And lastly, this one.{" "}
                                            <span className="text-body-secondary">Checkmate.</span>
                                        </h2>
                                        <p className="lead">
                                            And yes, this is the last block of representative placeholder
                                            content. Again, not really intended to be actually read, simply
                                            here to give you a better view of what this would look like with
                                            some actual content. Your content.
                                        </p>
                                    </div>
                                    <div className="col-md-5">
                                        <svg
                                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                                            width="500"
                                            height="500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            role="img"
                                            aria-label="Placeholder: 500x500"
                                            preserveAspectRatio="xMidYMid slice"
                                            focusable="false"
                                        >
                                            <title>Placeholder</title>
                                            <rect width="100%" height="100%" fill="var(--bs-secondary-bg)" />
                                            <text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">
                                                500x500
                                            </text>
                                        </svg>
                                    </div>
                                </div>

                                <hr className="featurette-divider" />
                            </div>

                            <footer className="container">
                                <p className="float-end">
                                    <a href="/">Back to top</a>
                                </p>
                                <p>
                                    &copy; 2017–2023 Company, Inc. &middot; <a href="/">Privacy</a>{" "}
                                    &middot; <a href="/">Terms</a>
                                </p>
                            </footer>
                        </main>

                    )
                })}
            </div>
        </div>
    )
}
export default Home;
