import React,{useState,useEffect} from "react";
import {useLocation} from 'react-router-dom';
//import {useNavigate} from "react-router-dom";
import Axios from "axios";
import {MathJax,MathJaxContext} from "better-react-mathjax";
import "../css/MathPage.css";
//import PdfViewer from "../components/PdfViewer";
import Constant from "../config/Constant";
import PostContentEdit from "../components/PostEdit";

const HOST = Constant.HOST;

export default function PostEdit() {
    const config = {
        loader: {load: ["input/asciimath"]}
    };
    //const navigate = useNavigate();
    const {state} = useLocation();
    const {id,title} = state;
    const [postContent,setPostContent] = useState([]);
    const [refs,setRefs] = useState([]);
    //const [showEdit,setShowEdit] = useState(false);
    const showEdit = true;
    const [ids,setIds] = useState();
    const [code,setCode] = useState();
    const [currentIndex,setCurrentIndex] = useState(0);


    useEffect(() => {
        Axios.get(`${HOST}/api/post/getcontent/id/${id}`)
            .then((result) => {
                setPostContent(result.data);
                const refs = (result.data).reduce((acc,value,index) => {
                    acc[index] = React.createRef();
                    return acc;
                },{});
                setRefs(refs);
            })
            .catch((error) => {
                console.log(error);
            });
    },[id]);

    const handleScroll = index =>
        refs[index].current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });



    const handleDeleteContent = (code) => {
        Axios.delete(`${HOST}/api/post/deletecontent/${code}`).then((response) => {
            alert("Content deleted!")
        });
    }

    const handleDeleteImage = (code,image) => {
        Axios.delete(`${HOST}/api/post/deleteimage/${code}/${image}`).then((response) => {
            alert("Content deleted!")
        });
    }

    const handleEditContent = (ids,code,index) => {
        //setShowEdit(true);
        setIds(ids);
        setCode(code);
        setCurrentIndex(index)
        document.documentElement.scrollTop = 0
        var objDiv = document.getElementById('page');
        document.documentElement.scrollTop = objDiv.scrollHeight;
    }



    const AElement = (props) => {
        var text = props.text;
        const checkA = text.indexOf(`[a]`);
        if(checkA > -1) {
            const aaa = text.split(`[a]`);
            return (
                <>
                    {aaa[0]}
                    <span
                        style={{
                            cursor: 'pointer',
                            color: 'blue',
                            textDecoration: 'underline'
                        }}
                        onClick={() => handleScroll(props.link)}
                    >{aaa[1]}</span>
                    {aaa[2]}
                </>
            )
        } else {
            return (
                <>{text}</>
            )
        }
    }

    const ContentElement = (props) => {
        var text = props.text;
        const checkS = text.indexOf(`[s]`);
        const checkA = text.indexOf(`[a]`);
        if(checkS > -1) {
            const sss = text.split(`[s]`);
            return (
                <>
                    {sss.map((key,val) => {
                        return (
                            ((val % 2 === 0) ?
                                (<AElement key={key} link={props.link} text={sss[val]} />) :
                                (<strong key={key}><AElement link={props.link} text={sss[val]} /></strong>)
                            )
                        )
                    })}
                </>
            )

        } else if(checkA > -1) {
            const aaa = text.split(`[a]`);
            return (
                <>
                    {aaa.map((val,key) => {
                        return (
                            ((key % 2 === 0) ?
                                (<AElement key={key} link={props.link} text={aaa[key]} />) :
                                <span
                                    key={key}
                                    style={{
                                        cursor: 'pointer',
                                        color: 'blue',
                                        textDecoration: 'underline'
                                    }}
                                    onClick={() => handleScroll(props.link)}
                                >{aaa[key]}</span>
                            )
                        )
                    })}
                </>
            )
        } else {
            return (
                <>{text}</>
            )
        }

    }

    const offEdit = (lastIndex) => {
        handleScroll(lastIndex);

        //setShowEdit(false);
        //console.log(lastIndex);
    }

    return (
        <div id='page' className='container-lg mt-5'>
            <hr className="featurette-divider" />
            <div style={{'color': 'rgb(80,80,80)','backgroundColor': 'rgb(200,200,200)','lineHeight': 1.3}}>
                <div id="wrapper">
                    <nav>
                        <h1>{title}</h1>
                        <div id="links">
                            <a href="/">Home</a>
                            <a href="/admin">Admin</a>
                            <a href="/postsedit">PostsEdit</a>
                        </div>
                    </nav>
                    <article>
                        <section>
                            <MathJaxContext config={config}>
                                {postContent.map((val,key) => {
                                    switch(val.tag) {
                                        case "section":
                                            return (
                                                <h2 ref={refs[key]} id={val.code} key={key}>{val.content}
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </h2>
                                            )
                                        case "head1":
                                            return (
                                                <h2 ref={refs[key]} id={val.code} key={key}>{val.content}
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </h2>
                                            )
                                        case "head2":
                                            return (
                                                <h3 ref={refs[key]} id={val.code} key={key}>{val.content}
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </h3>
                                            )
                                        case "text":
                                            return (
                                                <p ref={refs[key]} key={key}>
                                                    <ContentElement id={val.code} text={val.content} link={val.link} />
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </p>
                                            )
                                        case "span":
                                            return (
                                                <p ref={refs[key]} id={val.code} key={key}><span>{val.content}</span>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </p>
                                            )
                                        case "mark":
                                            return (
                                                <p ref={refs[key]} id={val.code} key={key}><mark>{val.content}</mark>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </p>
                                            )
                                        case "code":
                                            return (
                                                <p ref={refs[key]} id={val.code} key={key}><code>{val.content}</code>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </p>
                                            )
                                        case "image":
                                            return (
                                                <div ref={refs[key]} id={val.code} key={key}>
                                                    <img src={`${HOST}/post/` + val.content} alt="" style={{
                                                        "border": "1px solid black",
                                                        "marginLeft": "1em",
                                                        'marginBottom': '1em',
                                                        "height": "auto",
                                                        "width": "98%"
                                                    }}>
                                                    </img>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteImage(val.code,val.content))}>Delete</button>
                                                </div>

                                            )
                                        case "pdf":
                                            return (
                                                <div ref={refs[key]} id={val.code} key={key}>
                                                    <iframe title="PDF"
                                                        src={`${HOST}/post/` + val.content + "#toolbar=0"}
                                                        style={{
                                                            "border": "2px solid black",
                                                            "scrolling": "auto",
                                                            "toolbar": "1",
                                                            "marginLeft": "1em",
                                                            "height": "90vh",
                                                            "width": "100%"
                                                        }}
                                                    ></iframe>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteImage(val.code,val.content))}>Delete</button>
                                                </div>
                                            )
                                        case "math":
                                            return (
                                                <p ref={refs[key]} id={val.code} key={key}><MathJax>{val.content}</MathJax>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </p>
                                            )
                                        default:
                                            return (
                                                <p ref={refs[key]} id={val.code} key={key}>{val.content}
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleEditContent(val.id,val.code,key))}>Edit <span className="badge text-bg-secondary">{key} link {val.link}</span></button>
                                                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={(() => handleDeleteContent(val.code))}>Delete</button>
                                                </p>
                                            )
                                    }
                                })}
                            </MathJaxContext>
                        </section>
                    </article>
                </div>
            </div>
            {showEdit && <>
                <PostContentEdit ids={ids} code={code} />
                <button type="button" className="btn btn-outline-success" onClick={() => offEdit(currentIndex)}>Back</button>
            </>}
        </div>
    )
}