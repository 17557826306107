import {useState,useEffect} from "react";

function Navigation(props) {

    const [page0,setPage0] = useState(false);
    const [page1,setPage1] = useState(false);
    const [page2,setPage2] = useState(false);
    const [page3,setPage3] = useState(false);
    //const [page4,setPage4] = useState(false);
    const [page5,setPage5] = useState(false);
    const [page6,setPage6] = useState(false);
    const [hover0,setHover0] = useState(false);
    const [hover1,setHover1] = useState(false);
    const [hover2,setHover2] = useState(false);
    const [hover3,setHover3] = useState(false);
    //const [hover4,setHover4] = useState(false);
    const [hover5,setHover5] = useState(false);
    const [hover6,setHover6] = useState(false);


    useEffect(() => {
        switch(props.page) {
            case "Home":
                setPage0(true);
                setPage1(false);
                setPage2(false);
                setPage3(false);
                //setPage4(false);
                setPage5(false);
                setPage6(false);
                break;
            case "Catalogues":
                setPage0(false);
                setPage1(true);
                setPage2(false);
                setPage3(false);
                //setPage4(false);
                setPage5(false);
                setPage6(false);
                break;
            case "Posts":
                setPage0(false);
                setPage1(false);
                setPage2(true);
                setPage3(false);
                //setPage4(false);
                setPage5(false);
                setPage6(false);
                break;
            case "Art":
                setPage0(false);
                setPage1(false);
                setPage2(false);
                setPage3(true);
                //setPage4(false);
                setPage5(false);
                setPage6(false);
                break;
            case "UploadCatalogue":
                setPage0(false);
                setPage1(false);
                setPage2(false);
                setPage3(false);
                //setPage4(true);
                setPage5(false);
                setPage6(false);
                break;
            case "MathPage":
                setPage0(false);
                setPage1(false);
                setPage2(false);
                setPage3(false);
                //setPage4(false);
                setPage5(true);
                setPage6(false);
                break;
            case "ReportPage":
                setPage0(false);
                setPage1(false);
                setPage2(false);
                setPage3(false);
                //setPage4(false);
                setPage5(false);
                setPage6(true);
                break;
            default:
                setPage0(false);
                setPage1(false);
                setPage2(false);
                setPage3(false);
            //setPage4(false);
        }
    },[props.page]);


    const styleNormal = {
        "fontWeight": 400,
        "fontSize": "14px",
        "fontFamily": ["Lato","sans-serif"],
        "color": 'white',
        "width": "130px",
        "height": "25px",
        "textAlign": "center",
        "textTransform": "uppercase",
        "textDecoration": "none",
        "cursor": "pointer",
        "display": "inline-block",
        "letterSpacing": "3px",
        "transition": "all 0.5s ease-out"
    };
    const styleActive = {
        "fontWeight": 400,
        "fontSize": "14px",
        "fontFamily": ["Lato","sans-serif"],
        "color": 'orange',
        "width": "130px",
        "height": "25px",
        "textAlign": "center",
        "textTransform": "uppercase",
        "textDecoration": "none",
        "cursor": "pointer",
        "display": "inline-block",
        "letterSpacing": "3px",
        "transition": "all 0.5s ease-out"
    };



    // const handleClick = (e) => {
    //     const element = document.getElementById(`a${e}`);
    // }

    return (
        <div>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <div className="container-lg">
                    <a className="navbar-brand" href="/">+84 909763089</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <ul className="navbar-nav me-auto mb-2 mb-md-0">
                            <li className="nav-item" id="li0">
                                <a id="a0" style={(page0 || hover0 ? styleActive : styleNormal)}
                                    onMouseOver={(e) => {setHover0(true)}}
                                    onMouseLeave={(e) => {setHover0(false)}}
                                    className="nav-link" href="/">
                                    <i className="bi bi-house"></i> Home</a>
                            </li>
                            <li className="nav-item" id="li1">
                                <a id="a1" style={(page1 || hover1 ? styleActive : styleNormal)}
                                    onMouseOver={(e) => {setHover1(true)}}
                                    onMouseLeave={(e) => {setHover1(false)}}
                                    className="nav-link" href="/catalogues" >
                                    <i className="bi bi-people"></i> Catalog</a>
                            </li>
                            <li className="nav-item" id="li2">
                                <a style={(page2 || hover2 ? styleActive : styleNormal)}
                                    onMouseOver={(e) => {setHover2(true)}}
                                    onMouseLeave={(e) => {setHover2(false)}}
                                    className="nav-link" href="/posts">
                                    <i className="bi bi-people"></i> Posts</a>
                            </li>
                            <li className="nav-item" id="li3">
                                <a style={(page3 || hover3 ? styleActive : styleNormal)}
                                    onMouseOver={(e) => {setHover3(true)}}
                                    onMouseLeave={(e) => {setHover3(false)}}
                                    className="nav-link" href="/art">
                                    <i className="bi bi-book"></i> Art</a>
                            </li>
                            {/* <li className="nav-item" id="li4">
                                <a style={(page4 || hover4 ? styleActive : styleNormal)}
                                    onMouseOver={(e) => {setHover4(true)}}
                                    onMouseLeave={(e) => {setHover4(false)}}
                                    className="nav-link" href="/upcatalogue">
                                    <i className="bi bi-envelope"></i> upcata</a>
                            </li> */}
                            <li className="nav-item" id="li5">
                                <a style={(page5 || hover5 ? styleActive : styleNormal)}
                                    onMouseOver={(e) => {setHover5(true)}}
                                    onMouseLeave={(e) => {setHover5(false)}}
                                    className="nav-link" href="/mathpage">
                                    <i className="bi bi-envelope"></i> Math</a>
                            </li>
                            <li className="nav-item" id="li6">
                                <a style={(page6 || hover6 ? styleActive : styleNormal)}
                                    onMouseOver={(e) => {setHover6(true)}}
                                    onMouseLeave={(e) => {setHover6(false)}}
                                    className="nav-link" href="/admin">
                                    <i className="bi bi-envelope"></i> Admin</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )


};
export default Navigation;