import React from "react";
import {MathJax,MathJaxContext} from "better-react-mathjax";
import "../css/MathPage.css";
import Navigation from "../components/Navigation";
import PdfViewer from "../components/PdfViewer";
import Constant from "../config/Constant";

const HOST = Constant.HOST;
const url1 = (`${HOST}/img/Hinh_1.png`);
const url2 = (`${HOST}/img/Phuluc_C.png`);



export default function MathPage() {
    const config = {
        loader: {load: ["input/asciimath"]}
    };
    return (
        <div className='container-lg mt-5'>
            <Navigation page="MathPage" />
            <hr className="featurette-divider" />

            <div style={{'color': 'rgb(80,80,80)','backgroundColor': 'rgb(200,200,200)','lineHeight': 1.3}}>
                <div id="wrapper">
                    <nav>
                        <h1>TCVN 2737-2020</h1>
                        <div id="links">
                            <a href="/">Home</a>
                            <a href="#about">About</a>
                            <a href="#gettingStarted">Getting started</a>
                            <a href="http://asciimath.org/#syntax">Syntax</a>
                        </div>
                    </nav>
                    <article>
                        <section id="TaiTrongGio">
                            <MathJaxContext config={config}>
                                <h2>TẢI TRỌNG GIÓ</h2>
                                <h3> Tải trọng gió tác dụng lên công trình gồm các thành phần:</h3>
                                <p> <MathJax>{"`W_e`"} - áp lực pháp tuyến được  Áp lực pháp tuyến We đặt vào mặt ngoài công trình hoặc các cấu kiện.</MathJax></p>
                                <p> <MathJax>{"`W_f`"} - lực ma sát  hướng theo tiếp tuyến với mặt ngoài và tỷ lệ với diện tích hình chiếu bằng (đối với
                                    mái răng cưa, lượn sóng và mái có cửa trời) hoặc với diện tích hình chiếu đứng của nó (đối với
                                    tường có ban công hoặc lô-gia và các kết cấu tương tự).</MathJax>
                                </p>
                                <p> <MathJax>{"`W_i`"} -  áp lực pháp tuyến  tác dụng vào bề mặt bên trong của nhà có tường bao che không kín, có lỗ
                                    cửa tự mở hoặc mở thường xuyên. </MathJax>
                                </p>
                                <h3>  Tải trọng gió tác dụng lên công trình cũng có thể quy về hai thành phần:</h3>
                                <p> <MathJax>{"`W_x`"} - áp lực pháp tuyến theo phương X</MathJax></p>
                                <p> <MathJax>{"`W_y`"} - áp lực pháp tuyến theo phương Y</MathJax></p>


                                <h3>Tải trọng gió tiêu chuẩn W  tại độ cao z được xác định theo công thức sau: </h3>
                                <p style={{'textAlign': 'center'}}><MathJax>{"`W=1.2(W_0.k.c).(G_f.I_w)`"}</MathJax></p>
                                <p>1.2 -  là hệ số chuyển đổi áp lực gió từ chu kỳ lặp 20 năm lên chu kỳ lặp chuẩn 50 năm.</p>
                                <p><MathJax>{"`W_0`"} -  là áp lực gió cơ bản tương ứng với vận tốc gió cơ bản V 0  ở độ cao 10 m so với mặt đất lấy trung
                                    bình trong khoảng thời gian 3 giây, bị vượt trung bình một lần trong 20 năm, tương ứng với địa hình
                                    dạng B. <a href="#Cal_W0">Cách tính</a></MathJax></p>
                                <p>k -   là hệ số kể đến sự thay đổi áp lực gió theo độ cao và dạng địa hình tại độ cao z <a href="#Cal_k">Cách tính</a></p>
                                <p>c - hệ số khí động, lấy theo <a href="#Cal_c">Cách tính</a></p>
                                <p>
                                    <MathJax>
                                        {"`G_f`"} -  là hệ số giật <a href="#Cal_Gf">Cách tính</a>
                                    </MathJax>
                                </p>
                                <p>
                                    <MathJax>
                                        {"`I_w`"} -  là hệ số tầm quan trọng khi tính tải trọng gió, phụ thuộc vào mức độ quan trọng của công trình
                                        (cấp công trình xây dựng), xác định theo <a href="#Bang_7">Bảng 7</a> và <a href="#Cal_Iw">Cách tính</a>
                                    </MathJax>
                                </p>
                            </MathJaxContext>
                        </section>
                        <section id="Cal_W0">
                            <MathJaxContext config={config}>
                                <h3><MathJax>Áp lực gió cơ bản {"`W_0`"} <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></MathJax></h3>
                                <p id='Bang_3'><strong>Bảng 3</strong> - Giá trị của áp lực gió cơ bản theo bản đồ phân vùng áp lực gió trên lãnh thổ Việt Nam</p>

                                <table className="table table-hover" style={{'textAlign': 'center'}}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Vùng áp lực gió trên bản đồ</th>
                                            <th scope="col">I</th>
                                            <th scope="col">II</th>
                                            <th scope="col">III</th>
                                            <th scope="col">IV</th>
                                            <th scope="col">V</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row"><MathJaxContext config={config}><MathJax>{"`W_0`"}(kPa)</MathJax></MathJaxContext></th>
                                            <td>0.65</td>
                                            <td>0.95</td>
                                            <td>1.25</td>
                                            <td>1.55</td>
                                            <td>1.85</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>
                                    Công trình xây dựng ở vùng núi và hải đảo có cùng độ cao, cùng dạng địa hình và ở gần các
                                    trạm quan trắc khí tượng cho trong Phụ lục E thì giá trị áp lực gió lấy theo trị số độc lập của các
                                    trạm này (Bảng E1 và E2) <a href="#Phuluc_E">Phụ lục E</a> nhưng không thấp hơn giá trị cho ở <a href="#Phuluc_D">Phụ lục D</a>
                                </p>
                                <p>
                                    Công trình xây dựng ở những vùng có địa hình phức tạp (hẻm núi, giữa hai dãy núi song song,
                                    các cửa đèo…), giá trị của áp lực gió cơ bản <MathJax className=".p">{"`W_0`"}</MathJax> phải lấy theo số liệu của Viện Khí tượng thủy văn
                                    và biến đổi khí hậu hoặc Tổng cục Khí tượng thủy văn (đều thuộc Bộ Tài nguyên và môi trường)
                                    cung cấp hoặc kết quả khảo sát xây dựng đã được xử lý có kể đến kinh nghiệm sử dụng công trình.
                                    Khi đó giá trị áp lực gió cơ bản <MathJax>{"`W_0`"}</MathJax> (daN/m2
                                    ) xác định theo công thức sau:
                                </p>
                                <p style={{'textAlign': 'center'}}><MathJax>{"`W_0=0.0613V_0^2`"}</MathJax></p>
                            </MathJaxContext>
                        </section>
                        <section id="Cal_k">
                            <MathJaxContext config={config}>

                                <h3>Hệ số kể đến sự thay đổi áp lực gió k <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                                <p>
                                    Các giá trị của hệ số k kể đến sự thay đổi áp lực gió theo độ cao z so với mốc chuẩn và dạng địa
                                    hình, xác định theo <strong>Bảng 4</strong>
                                </p>
                                <p>
                                    Địa hình dạng A là địa hình trống trải, không có hoặc có rất ít vật cản cao không quá 1,5 m (bờ biển
                                    thoáng, mặt sông, hồ lớn, đồng muối, cánh đồng không có cây cao...), xem Hình H.1, <a href="#Phuluc_H">Phụ lục H.</a>
                                </p>

                                <p>
                                    Địa hình dạng B là địa hình tương đối trống trải, có một số vật cản thưa thớt cao không quá 10 m
                                    (vùng ngoại ô ít nhà, thị trấn, làng mạc, rừng thưa hoặc rừng non, vùng trồng cây thưa…), xem Hình
                                    H.2, <a href="#Phuluc_H">Phụ lục H</a>
                                </p>
                                <p>
                                    Địa hình dạng C là địa hình bị che chắn mạnh, có nhiều vật cản sát nhau cao từ 10 m trở lên (trong
                                    thành phố, vùng rừng rậm...), xem Hình H.3, <a href="#Phuluc_H">Phụ lục H</a>
                                </p>
                                <p>
                                    Công trình được xem là nằm trong dạng địa hình nào nếu tính chất của dạng địa hình đó không thay
                                    đổi trong khoảng cách 30H khi H ≤ 60 m và 2 km khi H <code>&gt;</code> 60m tính từ mặt đón gió của công trình, H
                                    là chiều cao công trình.
                                </p>



                                <p id='Bang_4'><strong>Bảng 4</strong> - Hệ số k kể đến sự thay đổi áp lực gió theo độ cao và dạng địa hình</p>
                                <table className="table table-hover" style={{'textAlign': 'center'}}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Độ cao (m)</th>
                                            <th scope="col">Địa hình A</th>
                                            <th scope="col">Địa hình B</th>
                                            <th scope="col">Địa hình C</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>1.00</td>
                                            <td>0.80</td>
                                            <td>0.47</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">5</th>
                                            <td>1.07</td>
                                            <td>0.88</td>
                                            <td>0.54</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">10</th>
                                            <td>1.18</td>
                                            <td>1.00</td>
                                            <td>0.66</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">15</th>
                                            <td>1.24</td>
                                            <td>1.08</td>
                                            <td>0.75</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">20</th>
                                            <td>1.29</td>
                                            <td>1.13</td>
                                            <td>0.80</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">30</th>
                                            <td>1.37</td>
                                            <td>1.22</td>
                                            <td>0.89</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">40</th>
                                            <td>1.43</td>
                                            <td>1.28</td>
                                            <td>0.97</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">50</th>
                                            <td>1.47</td>
                                            <td>1.34</td>
                                            <td>1.03</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">60</th>
                                            <td>1.51</td>
                                            <td>1.38</td>
                                            <td>1.08</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">80</th>
                                            <td>1.57</td>
                                            <td>1.45</td>
                                            <td>1.18</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">100</th>
                                            <td>1.62</td>
                                            <td>1.51</td>
                                            <td>1.25</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">150</th>
                                            <td>1.72</td>
                                            <td>1.63</td>
                                            <td>1.40</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">200</th>
                                            <td>1.99</td>
                                            <td>1.88</td>
                                            <td>1.69</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">250</th>
                                            <td>1.99</td>
                                            <td>1.97</td>
                                            <td>1.80</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">300</th>
                                            <td>1.99</td>
                                            <td>1.97</td>
                                            <td>1.90</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">350</th>
                                            <td>1.99</td>
                                            <td>1.97</td>
                                            <td>1.98</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">400</th>
                                            <td>1.99</td>
                                            <td>1.97</td>
                                            <td>1.98</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pre>
                                    <p> CHÚ THÍCH:</p>
                                    <p> 1. Đối với độ cao trung gian cho phép xác định giá trị k bằng cách nội suy tuyến tính các giá trị trong Bảng 4.</p>
                                    <p> 2. Khi xác định tải trọng gió cho một công trình, các dạng địa hình có thể khác nhau theo hướng gió khác nhau.</p>
                                    <p> 3. Hệ số k có thể được xác định theo công thức sau (5m≤ Z ≤Zg):</p>
                                    <p> <MathJax>             {"`k=2.01(Z/Z_g)^frac{2}{prop}`"}</MathJax></p>
                                    <p> Trong đó:</p>
                                    <p><MathJax> Đối với địa hình dạng A: {"`prop`"} = 11.5, {"`Z_g`"} = 213.36m, k ≤ 1.99</MathJax></p>
                                    <p><MathJax> Đối với địa hình dạng B: {"`prop`"} =  9.5, {"`Z_g`"} = 274.32m, k ≤ 1.97</MathJax></p>
                                    <p><MathJax> Đối với địa hình dạng A: {"`prop`"} =  7.0, {"`Z_g`"} = 365.76m, k ≤ 1.98</MathJax></p>
                                </pre>
                                <p>
                                    Khi mặt đất xung quanh nhà và công trình không bằng phẳng thì mốc chuẩn để tính độ cao được
                                    xác định theo <a href="#Phuluc_G">Phụ lục G</a>
                                </p>
                                <p>
                                    Sơ đồ phân bố tải trọng gió lên nhà, công trình hoặc các cấu kiện và hệ số khí động c được xác
                                    định theo chỉ dẫn trong <a href="#Bang_5">Bảng 5</a>. Các giá trị trung gian cho phép nội suy tuyến tính.
                                    Mũi tên trong <a href="#Bang_5">Bảng 5</a> chỉ hướng gió thổi lên nhà, công trình hoặc cấu kiện. Hệ số khí động được xác
                                    định như sau
                                </p>
                                <p>
                                    Đối với mặt hoặc điểm riêng lẻ của nhà và công trình lấy như hệ số áp lực đã cho (sơ đồ 1 đến
                                    sơ đồ 33 <a href="#Bang_5">Bảng 5</a>).
                                    Giá trị dương của hệ số khí động ứng với chiều áp lực gió hướng vào bề mặt công trình, giá trị âm
                                    ứng với chiều áp lực gió hướng ra ngoài công trình.
                                </p>
                            </MathJaxContext>

                        </section>
                        <section id="Cal_c">
                            <MathJaxContext config={config}>
                                <h3>Hệ số khí động c <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                                {/* <iframe title="PDF"
                                src={url + "#toolbar=0"}
                                style={{
                                    "border": "2px solid black",
                                    "scrolling": "auto",
                                    "toolbar": "1",
                                    "marginLeft": "1em",
                                    "height": "90vh",
                                    "width": "100%"
                                }}
                            ></iframe> */}
                                <p id='Bang_5'><strong>Bảng 5</strong> - Hệ số khí động c</p>
                                <PdfViewer pdfFile={'TCVN-2737-2020_BANG_5.pdf'} />
                                <p>
                                    Tại  vùng  lân  cận  các  đường  bờ  mái,  bờ  nóc  và  chân  mái,  các  cạnh  tiếp  giáp  giữa
                                    tường ngang và tường dọc, nếu áp lực ngoài có giá trị âm thì cần kể đến áp lực cục  bộ (Hình 1).
                                </p>
                                <img src={url1} alt="" style={{
                                    "border": "1px solid black",
                                    "marginLeft": "1em",
                                    'marginBottom': '1em',
                                    "height": "auto",
                                    "width": "98%"
                                }}
                                ></img>
                                <p> <MathJax> <strong>Bảng 6</strong>- Hệ số áp lực cục bộ {"`C_(CB)`"}</MathJax></p>
                                <table className="table table-hover">
                                    <thead style={{'textAlign': 'center'}}>
                                        <tr>
                                            <th scope="col">Vùng có áp lực cục bộ</th>
                                            <th scope="col"><MathJax>{"`C_(CB)`"}</MathJax></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td >Vùng 1: Có bề rộng a tính từ bờ mái, bờ nóc, chân mái và  góc   tường.</td>
                                            <td>2.0</td>
                                        </tr>
                                        <tr>
                                            <td>Vùng 2: Có bề rộng a tiếp giáp với vùng 1 </td>
                                            <td>1.5</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <pre>
                                    <p> CHÚ THÍCH:</p>
                                    <p><MathJax>1. Tại các vùng có áp lực cục bộ, hệ số khí động c cần được nhân với hệ số áp lực cục bộ {"`C_(CB)`"}</MathJax></p>
                                    <p> 2. Khi tính lực tổng hợp trên một công trình, một bức tường hoặc một hệ mái không được sử dụng các  hệ số áp lực
                                        cục bộ này.
                                    </p>
                                    <p> 3.  Bề  rộng  a   lấy  bằng  giá  trị  nhỏ  nhất  trong  3  giá  trị  sau:  0.1B,  0.1L,  0.1H  nhưng  không  nhỏ  hơn  1,5 m. Kích
                                        thước B, L, H xem trên Hình 1.
                                    </p>
                                    <p><MathJax> 4. Hệ số áp lực cục bộ chỉ áp dụng cho các nhà có độ dốc mái {"`propto  > 10^0`"}</MathJax></p>
                                    <p> 5. Khi có mái đua thì diện tích bao gồm cả diện tích mái đua, áp lực phần mái dua lấy bằng phần  tường sát dưới
                                        mái đua. </p>
                                </pre>
                                <p>Đối với các công trình có hình dạng phức tạp, không xác định được hệ số khí động c cho
                                    trong <a href="#Bang_5">Bảng 5</a> , có thể phải xem xét, lấy số liệu trên các kết quả thí nghiệm gió trong các ống thổi khí
                                    động hoặc các tài liệu kỹ thuật chuyên sâu khác.</p>
                                <p> Đối với các thiết bị gắn trên công trình (cột ăng ten, máy phát điện gió, hệ thống điện mặt trời
                                    v.v.) tham khảo các tiêu chuẩn kỹ thuật hay tài liệu hướng dẫn riêng. Trường hợp cần thiết có thể
                                    phải thực hiện thí nghiệm gió trong ống thổi khí động. </p>
                            </MathJaxContext>
                        </section>
                        <section id="Cal_Gf">
                            <MathJaxContext config={config}>
                                <h3><MathJax>Hệ số giật {"`G_f`"} <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></MathJax></h3>
                                <p> Hệ số giật là hệ số phản ứng của kết cấu dưới tác dụng của tải trọng gió (kể cả thành phần
                                    phản ứng tĩnh "xung của gió" và thành phần phản ứng động "cộng hưởng").
                                </p>
                                <p>
                                    <MathJax>Đối với kết cấu "cứng" (có chu kỳ dao động riêng thứ nhất T1 ≤ 1s) thì {"`G_f`"} có thể lấy bằng 0.85.</MathJax>
                                </p>
                                <p>
                                    <MathJax>Đối với kết cấu "mềm" (có chu kỳ dao động riêng thứ nhất T1  <code>&gt;</code> 1s) thì {"`G_f`"} được xác định
                                        <a href="#PhuLuc_G"> Phụ lục G.</a></MathJax>
                                </p>
                                <p>
                                    Với nhà cao tầng có chu kỳ dao động riêng thứ nhất T1 <code>&gt;</code> 1s và chiều cao không quá 150 m, cho
                                    phép xác định hệ số giật  theo các công thức sau:
                                </p>
                                <p>
                                    <MathJax>{"`G_f=0.85+H/2840`"}   đối với nhà bê tông cốt thép</MathJax>
                                </p>
                                <p>
                                    <MathJax>{"`G_f=0.85+H/1010`"}   đối với nhà kết cấu thép</MathJax>
                                </p>
                                <pre>
                                    <p> Trong đó:</p>
                                    <p> H là chiều cao của công trình(m).</p>
                                </pre>

                            </MathJaxContext>
                        </section>
                        <section id="Cal_Iw">
                            <MathJaxContext config={config}>
                                <h3><MathJax>Hệ số tầm quan trọng {"`I_w`"} <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></MathJax></h3>
                                <p>
                                    <MathJax><strong>Bảng 7</strong> - Hệ tầm quan trọng {"`I_w`"}</MathJax>
                                </p>
                                <table className="table table-hover" >
                                    <thead>
                                        <tr style={{'textAlign': 'center'}}>
                                            <th scope="col">
                                                Mức độ quan trọng của công trình
                                            </th>
                                            <th scope="col">
                                                Công trình có tầm quan trọng, nếu xảy ra sự cố thì
                                                sẽ ảnh hưởng đến người dân, xã hội, kinh tế và
                                                môi trường
                                            </th>
                                            <th scope="col"><MathJax>{"`I_w`"}</MathJax></th>
                                        </tr>
                                    </thead>
                                    <tbody style={{'textAlign': 'justify'}}>
                                        <tr>
                                            <th scope="row">Đặc biệt, I</th>
                                            <td>
                                                Công trình có tầm quan trọng đối với việc bảo vệ con
                                                người và cộng đồng, chức năng không được gián đoạn
                                                trong  quá  trình  xảy  ra  gió  bão  và  các  công  trình  đặc
                                                biệt quan trọng khác; công trình dùng làm nơi sơ tán,
                                                trú  ẩn  khi  có  bão  lớn,  siêu  bão  và  công  trình  phòng
                                                chống thiên tai; các công trình chứa chất độc hại nếu
                                                xảy  ra  sự  cố  thì  ảnh  hưởng  nghiêm  trọng  đến  môi
                                                trường,  người  dân  và  xã  hội;  những  công  trình  quan
                                                trọng có giá trị kinh tế lớn, có ý nghĩa quan trọng với
                                                chính trị, xã hội v.v. Ví dụ: Công trình cao hơn 75 m (từ
                                                25 tầng trở lên); cột điện, tháp viễn thông có chiều cao
                                                <code>&gt;</code> 150 m; bệnh viện 1000 giường trở lên; trụ sở UBND
                                                tỉnh và cấp tương đương trở lên v.v.
                                            </td>
                                            <td>1.15</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">II, III</th>
                                            <td>Các công trình không thuộc cấp đặc biệt, I và IV </td>
                                            <td>1.00</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">IV </th>
                                            <td>Công trình có tầm quan trọng thứ yếu đối với sự an
                                                toàn sinh mạng con người, ví dụ: nhà và công trình dân
                                                dụng, công trình công nghiệp có chiều cao nhỏ hơn 6
                                                m; công trình tạm v.v. </td>
                                            <td>0.87</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>
                                    Khi tính toán kiểm tra gia tốc dao động tại đỉnh công trình ảnh hưởng đến tiện nghi sử dụng
                                    người ở và làm việc thì dùng vận tốc gió 10 phút, tương ứng với chu kỳ lặp T lấy bằng 1 năm đến
                                    10 năm, khuyến nghị lấy chu kỳ lặp lấy tối thiểu là 1 năm. Khi đó, vận tốc gió 10 phút (lấy trung
                                    bình trong khoảng thời gian 10 phút) tại độ cao 10 m địa hình dạng B, chu kỳ lặp 1 năm được xác
                                    định theo công thức sau:
                                </p>
                                <p style={{'textAlign': 'center'}}>
                                    <MathJax>{"`V_(10m,1)=0.42sqrt((1.2W_0)/0.0613)`"}</MathJax>
                                </p>
                                <p>
                                    Đối với công trình nhạy cảm với tải trọng gió (như nhà siêu cao tầng (trên 200 m), cầu dây
                                    văng vượt nhịp lớn v.v.), hoặc đối với trường hợp cần tính toán ổn định khí động của kết cấu, tính
                                    toán tác dụng của gió ngang, galloping, gió môi trường (ảnh hưởng gió lên các công trình lân cận),
                                    ảnh hưởng của tải trọng gió lên kết cấu bao che, tác động của lốc xoáy, hoặc khi cần phải đưa ra
                                    các giải pháp giảm dao động của kết cấu khi chịu tải trọng gió v.v. có thể phải tiến hành thí nghiệm
                                    gió trong ống thổi khí động và thực hiện các nghiên cứu riêng chuyên sâu khác. Tuy nhiên, các số
                                    liệu của kết quả thí nghiệm gió và nghiên cứu riêng không thấp hơn 85% số liệu so với tính toán
                                    theo tiêu chuẩn này.
                                </p>
                                <p>
                                    Cho phép lấy 60% giá trị áp lực gió cơ bản (0,6W 0 ) khi tính toán công trình trong quá trình thi
                                    công.
                                </p>

                            </MathJaxContext>
                        </section>
                        <section id='Phuluc_C'>
                            <h3>Phụ lục C. <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                            <img src={url2} alt="" style={{
                                "border": "1px solid black",
                                "marginLeft": "1em",
                                'marginBottom': '1em',
                                "height": "auto",
                                "width": "98%"
                            }}></img>
                        </section>
                        <section id='Phuluc_D'>
                            <h3>Phụ lục D. <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                            <PdfViewer pdfFile={'TCVN-2737-2020_PL_D.pdf'} />
                        </section>
                        <section id='Phuluc_E'>
                            <h3>Phụ lục E. <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                            {/* <iframe title="PDF"
                                src={url3 + "#toolbar=1"}
                                style={{
                                    "border": "2px solid black",
                                    "scrolling": "auto",
                                    "toolbar": "1",
                                    "marginLeft": "1em",
                                    "height": "850px",
                                    "width": "100%"
                                }}
                            ></iframe> */}
                            <PdfViewer pdfFile={'TCVN-2737-2020_PL_E.pdf'} />
                        </section>
                        <section id='Phuluc_F'>
                            <h3>Phụ lục F. <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                            <PdfViewer pdfFile={'TCVN-2737-2020_PL_F.pdf'} />
                        </section>
                        <section id='Phuluc_G'>
                            <h3>Phụ lục G. <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                            <PdfViewer pdfFile={'TCVN-2737-2020_PL_G.pdf'} />
                        </section>
                        <section id='Phuluc_H'>
                            <h3>Phụ lục H. <a href="#TaiTrongGio"><i className="bi bi-house"></i></a></h3>
                            <PdfViewer pdfFile={'TCVN-2737-2020_PL_H.pdf'} />
                        </section>




                    </article>
                </div>
            </div>
        </div >
    );
}


