import React,{useState} from 'react'
import Axios from 'axios'
import {useNavigate} from "react-router-dom";
import Constant from "../config/Constant";

const HOST = Constant.HOST;

const ReadCatalogue = (props) => {
    const [like,setLike] = useState(0);
    const navigate = useNavigate();

    const increaseLike = (id,likes,pages) => {
        Axios.post(`${HOST}/api/catalogue/like/${id}`).then((response) => {
            alert("you liked a post")
        });

        like > likes ? setLike(like + 1) : setLike(likes + 1);
        navigate('/readcatalogue',{state: {id: id,pages: pages}})
    };

    return (
        <div>
            <button type="button" className="btn btn-primary position-relative" onClick={(() => increaseLike(props.id,props.likes,props.pages))}>
                Read more
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                    {like > props.likes ? like : props.likes}
                    <span className="visually-hidden">unread messages</span>
                </span>
            </button>
        </div>
    );
};

export default ReadCatalogue;