
function Manage() {
    return (
        <div id="wrapper">
            <nav>
                <h1>Admin</h1>
                <div id="links">
                    <a href="/">Home</a>
                    <a href="/homeupload">U_Home</a>
                    <a href="/catalogueupload">U_Catalogue</a>
                    <a href="/catalogueedit">E_Catalogue</a>
                    <a href="/postsedit">PM</a>
                    <a href={process.env.PUBLIC_URL + "FileManager.html"}>FM</a>
                </div>
            </nav>
        </div>
    );
}

export default Manage;
